import { createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "../../config";
import { toast } from "react-toastify";
import api from "../../config/api";


const getAssignmentData = createAsyncThunk("GET_ASSIGNMENT_DATA", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ASSIGNMENT_LIST, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})

const getAssignmentOrder = createAsyncThunk("GET_ASSIGNMENT_ORDER", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ASSIGNMENT_ORDER, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error);
    }
})

const acceptAssignmentData = createAsyncThunk("ACCEPT_ASSIGNMENT", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ACCEPT_ASSIGNMENT, values);
        toast.success(data?.ResponseMsg);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})

const getOrderDataByStatus = createAsyncThunk("STATUS_ORDER_DATA", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.STATUS_ORDER_LIST, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})
const getProductFeedback = createAsyncThunk("GET_PRODUCT_FEEDBACK", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.PRODUCT_FEEDBACK, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})
const getProductDetails = createAsyncThunk("GET_ORDER_DETAILS", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.PRODUCT_DETAILS, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})
const getAssignment = createAsyncThunk('GET_ASSIGNMENT', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.ASSIGNMENT.LIST, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})
const getPendingAssignment = createAsyncThunk('GET_PENDING_ASSIGNMENT', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.ASSIGNMENT.PENDING_ASSIGNMENT, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})
const addAssignment = createAsyncThunk('ADD_ASSIGNMENT', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.ASSIGNMENT.ADD, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const getAssignmentDetails = createAsyncThunk('GET_ASSIGNMENT_DETAILS', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.ASSIGNMENT.DETAILS, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})


export { getAssignmentData,getPendingAssignment, getAssignmentOrder, acceptAssignmentData, getOrderDataByStatus, getProductFeedback, getProductDetails, getAssignment, addAssignment, getAssignmentDetails }
