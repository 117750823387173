import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api";
import { toast } from "react-toastify";
import { endPoints } from "../../config";

const getReview = createAsyncThunk('GET_REVIEW', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.DASHBOARD.REVIEW, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const readyToWarehouse = createAsyncThunk('READY_TO_WAREHOUSE', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.DASHBOARD.READY_TO_WAREHOUSE, values);
        toast.success(data.ResponseMsg)
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const orderAddToAssignment = createAsyncThunk('ORDER_ADD_TO_ASSIGNMENT', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.DASHBOARD.ORDER_ADD_TO_ASSIGNMENT, values);
        toast.success(data.ResponseMsg)
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

export { getReview, readyToWarehouse , orderAddToAssignment }