import React from "react";

import { Link, useNavigate } from "react-router-dom";
import Button from "../components/common/Button/Button";
import { GoArrowLeft } from "react-icons/go";
import { imageUrl } from "../config/url";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { ForgotPasswordSchema } from "../config/schema";
import { yupResolver } from "@hookform/resolvers/yup";
import FormProvider from "../components/common/Form/FormProvider";
import TextField from "../components/custom/TextField";
import { AUTH_PATH } from "../config/path";
import { forgot_password } from "../redux/actions/auth.action";
import ButtonLoading from "../components/custom/ButtonLoading";

const ForgetPassword = () => {
  const { loading } = useSelector((state) => state.auth);
  const defaultValues = {
    email: "",
  };
  const dispatch = useDispatch();
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ForgotPasswordSchema),
  });
  const navigate = useNavigate();
  const {
    handleSubmit,
    formState: { errors },
  } = methods;
  const onSubmit = (values) => {
    dispatch(forgot_password(values))
      .unwrap()
      .then(() => {
        navigate(AUTH_PATH.verifyOTP);
      })
      .catch((error) => {});
  };
  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full bg-mainBG">
      <div className="max-md:px-5 flex flex-col items-center justify-center sm:py-0 py-10 space-y-10 xs:space-y-20 md:space-y-36">
        <div>
          <img
            // className="w-[344px] h-[436px] "
            src={`${imageUrl}images/MessageNoti.png`}
            alt="hand-logo"
          />
        </div>
        {/* <div className="lg:px-0 md:px-5 flex flex-col gap-y-2">
          <h1 className="text-3xl xs:text-[34px] max-md:leading-[44px] md:text-4xl font-bold text-[#fff] text-center">
            lorem ipsum dummy text
          </h1>
          <p className="text-lg md:text-2xl text-[#fff] font-normal text-center  lg:w-[492px]">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div> */}
      </div>
      <div className="w-full max-md:px-5 flex flex-col items-center justify-center bg-white h-screen ">
        <div className="flex justify-center mb-6">
          <img src={`${imageUrl}images/forgetPassword.png`} alt="lock" />
        </div>
        <FormProvider
          methods={methods}
          onSubmit={handleSubmit(onSubmit)}
          className="w-full"
        >
          <h2 className="text-mainBG text-3xl md:text-1xl  font-semibold text-center">
            Forgot Password?
          </h2>
          <p className="text-lg mt-2 font-medium text-textHeading text-center">
            No worries, we’ll send you instruction.
          </p>

          <div className="mt-8 flex  justify-center items-center   w-full">
            <div className="flex flex-col space-y-8 md:w-[360px] w-full ">
              <TextField
                placeholder={"Email"}
                name={"email"}
                icon={
                  <img src={`${imageUrl}icons/EmailIcon.svg`} alt="email" />
                }
              />

              <Button
                text={loading ? <ButtonLoading /> : "Send Code"}
                buttonType="submit"
                className="py-3 flex justify-center rounded-[10px] font-medium text-lg"
                type={true}
              ></Button>
            </div>
          </div>
          <div className="flex justify-center mt-20 ">
            <Link
              className="flex items-center gap-3 text-[#808080] text-lg font-normal"
              to="/"
            >
              <GoArrowLeft /> <span>Back to Sign In</span>
            </Link>
          </div>
        </FormProvider>
      </div>
    </div>
  );
};

export default ForgetPassword;
