import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'
import { imageUrl } from '../config/url'

const ProtectBranch = () => {

    if (localStorage.getItem('token') && localStorage.getItem('role') == "branch") {
        return <Outlet />
    } else {
        localStorage.removeItem("user_id")
        localStorage.removeItem("role")
        localStorage.removeItem("name")
        localStorage.removeItem("token")
        localStorage.removeItem("email")
        return <Navigate to={"/"} />
    }

}

export default ProtectBranch;
