import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import Button from "../components/common/Button/Button";
import DataTable from "../components/common/Tabel/DataTabel";
import TransitOrders from "./TransitOrders";
import useColumns from "../components/hooks/useColumns";
import ViewDeliveryModal from "../components/common/Modal/ViewDeliveryModal";
import RatingModal from "../components/common/Modal/RatingModal";
import { useNavigate, useParams } from "react-router-dom";
import AssignModal from "../components/common/Modal/AssignModal";
import BreadCrumb from "../components/common/BreadCrumb";
import { useDispatch, useSelector } from "react-redux";
import { getAssignmentOrder } from "../redux/actions/assignment.action";
import GenericTable from "../components/custom/GenericTable";
import { options } from "../data/data";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";

const Products = () => {
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("Transit");
  const { loading, total, orders } = useSelector((state) => state.assignment);
  const navigate = useNavigate();
  const [isClose, setIsClose] = useState(false);
  const [openModal, setOpenModal] = useState({ open: false, data: null });

  const { id } = useParams();
  const [assignmentId, setAssignmentId] = useState({ assignment_id: id });

  const handleView = (data) => {
    setOpenModal({
      open: true,
      data: data,
    });
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const { productsColumns } = useColumns({ handleView });

  const handleBackButton = () => {
    navigate(-1);
  };

  return (
    <>
      <Layout title="Orders" role="branchAdmin">
        <div className="px-4">
          <BreadCrumb title={`Assignment ${id}`} onClick={handleBackButton} />
        </div>
        {/* <DataTable columns={productsColumns} data={orders} /> */}
        <GenericTable
          limitVal={limitVal}
          getData={getAssignmentOrder}
          setLimitVal={setLimitVal}
          total={total}
          loading={loading}
          search={search}
          data={orders}
          extra={assignmentId}
          closeModal={isClose}
          status={isClose}
          columns={productsColumns}
          message="Products Not Found"
        />
        <AssignModal
          openModal={openModal}
          closeModal={() => {
            setIsClose(true);
            setOpenModal({
              open: false,
              data: null,
            });
          }}
        />
      </Layout>
    </>
  );
};

export default Products;
