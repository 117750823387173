import { createSlice } from "@reduxjs/toolkit";
import { getOrders } from "../actions/order.action";


const orderSlice=createSlice({
    name:"order",
    initialState:{
        loading:false,
        total:0,
        orderData:[]
    },
    reducers:{
        
    },
    extraReducers:(builder)=>{
        builder
        .addCase(getOrders.pending,(state,action)=>{
            state.loading=true
        })
        .addCase(getOrders.fulfilled,(state,action)=>{
            state.total=action.payload.data.totalRecords
            state.orderData=action.payload.data.orders
            state.loading=false
        })
        .addCase(getOrders.rejected,(state,action)=>{
            state.total=0
            state.loading=false
            state.orderData=[]
        })
       
      
       
    }
})

export default orderSlice.reducer;
