import React, { useCallback, useEffect, useState } from "react";
import DataTable from "../components/common/Tabel/DataTabel";
import useColumns from "../components/hooks/useColumns";
import AssignModal from "../components/common/Modal/AssignModal";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  acceptAssignmentData,
  getAssignmentData,
} from "../redux/actions/assignment.action";
import GenericTable from "../components/custom/GenericTable";
import { options } from "../data/data";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";

const TransitOrders = () => {
  const dispatch = useDispatch();
  const [limitVal, setLimitVal] = useState(options[1]);
  const [activeTab, setActiveTab] = useState(1);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const [shippingType, setShippingType] = useState({
    shipping_type: activeTab === 1 ? "cargo" : "air_cargo",
  });
  const navigate = useNavigate();
  const [openModal, setOpenModal] = useState(false);
  const { loading, total, assignment } = useSelector(
    (state) => state.assignment
  );

  const handleView = (rowData) => {
    navigate(`/branch-orders/${rowData._id}`);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleAccept = async (data) => {
    dispatch(
      acceptAssignmentData({
        user_id: localStorage.getItem("user_id"),
        assignment_id: data?._id,
      })
    )
      .unwrap()
      .then(() => {
        dispatch(
          getAssignmentData({
            user_id: localStorage.getItem("user_id"),
            shipping_type: activeTab === 1 ? "cargo" : "air_cargo",
            page: 1,
            limit: 10,
            search: "",
          })
        );
      })
      .catch(() => { });
  };

  const { branchOrdersColumns } = useColumns({ handleView, handleAccept });

  return (
    <>
      <div className="w-full flex mt-6">
        <div
          className={`w-full  py-3 ${activeTab === 1
              ? "font-semibold text-base border-b-2 border-[#383F90]"
              : "text-base font-normal border-b-2 border-[#E7E6E9] text-[#808080]"
            }`}
          onClick={() => {
            setActiveTab(1);
            setShippingType({ shipping_type: "cargo" });
          }}
        >
          Cargo
        </div>
        <div
          className={`w-full  py-3 ${activeTab === 2
              ? "font-semibold text-base border-b-2 border-[#383F90]"
              : "text-base font-normal border-b-2 border-[#E7E6E9] text-[#808080]"
            }`}
          onClick={() => {
            setActiveTab(2);
            setShippingType({ shipping_type: "air_cargo" });
          }}
        >
          Air Cargo
        </div>
      </div>
      <div>
        <GenericTable
          columns={branchOrdersColumns}
          data={assignment}
          getData={getAssignmentData}
          limitVal={limitVal}
          setLimitVal={setLimitVal}
          total={total}
          loading={loading}
          search={search}
          status={shippingType}
          extra={shippingType}
          height="h-[65vh]"
          message="Transit Order Not Found"
        />
        <AssignModal
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      </div>
    </>
  );
};

export default TransitOrders;
