import { createSlice } from "@reduxjs/toolkit";
import { getReview, orderAddToAssignment, readyToWarehouse } from "../actions/review.action";

const reviewdSlice = createSlice({
    name: "review",
    initialState: {
        loading: false,
        reviewData: []
    },
    reducers: {
        removeReviewData: (state, action) => {
            state.reviewData = []
        }
    },
    extraReducers: (builder) => {
        builder
            .addCase(getReview.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getReview.fulfilled, (state, action) => {
                state.reviewData = action.payload.data
                state.loading = false
            })
            .addCase(getReview.rejected, (state, action) => {
                state.loading = false
                state.reviewData = []
            })
            .addCase(readyToWarehouse.pending, (state, action) => {
                state.loading = true
            })
            .addCase(readyToWarehouse.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(readyToWarehouse.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(orderAddToAssignment.pending, (state, action) => {
                state.loading = true
            })
            .addCase(orderAddToAssignment.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(orderAddToAssignment.rejected, (state, action) => {
                state.loading = false
            })


    }
})

export default reviewdSlice.reducer;
export const { removeReviewData } = reviewdSlice.actions
