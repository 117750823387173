import { createSlice } from "@reduxjs/toolkit";
import { getDriver } from "../actions/auth.action";
import { addDriverData, deleteDriverData } from "../actions/driver.action";

const driverSlice = createSlice({
    name: "driver",
    initialState: {
        loading: false,
        total: 0,
        drivers: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getDriver.pending, (state, action) => {
            state.loading = true;
        }).addCase(getDriver.fulfilled, (state, action) => {
            state.total = action.payload.data.totalRecords
            state.drivers = action.payload.data.drivers
            state.loading = false
        }).addCase(getDriver.rejected, (state, action) => {
            state.total = 0
            state.loading = false;
            state.drivers = []
        })
        builder.addCase(addDriverData.pending, (state, action) => {
            state.loading = true;
        }).addCase(addDriverData.fulfilled, (state, action) => {
            state.loading = false
        }).addCase(addDriverData.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(deleteDriverData.pending, (state, action) => {
            state.loading = true
        }).addCase(deleteDriverData.fulfilled, (state, action) => {
            state.loading = false;  
        }).addCase(deleteDriverData.rejected, (state, action) => {
            state.loading = false;
        })
    }
})

export default driverSlice.reducer