import { createSlice } from "@reduxjs/toolkit";
import { assignOrdertoDriver, getUserData } from "../actions/user.action";

const userSlice = createSlice({
    name: 'user',
    initialState: {
        loading: false,
        total: 0,
        users: []
    },
    reducers: {
    },
    extraReducers: (builder) => {
        builder.addCase(getUserData.pending, (state, action) => {
            state.loading = true
        }).addCase(getUserData.fulfilled, (state, action) => {
            state.total = action.payload.data.totalRecords
            state.users = action.payload.data.users
            state.loading = false;
        }).addCase(getUserData.rejected, (state, action) => {
            state.total = 0;
            state.users = [];
            state.loading = false
        })
        builder.addCase(assignOrdertoDriver.pending, (state, action) => {
            state.loading = true
        }).addCase(assignOrdertoDriver.fulfilled, (state, action) => {
            state.loading = false
        }).addCase(assignOrdertoDriver.rejected, (state, action) => {
            state.loading = false
        })
    }
})

export default userSlice.reducer