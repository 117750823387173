import { createSlice } from "@reduxjs/toolkit";
import { getFund } from "../actions/fund.action";

const fundSlice = createSlice({
    name: "fund",
    initialState: {
        loading: false,
        fundData: []
    },
    reducers: {
        
    },
    extraReducers: (builder) => {
        builder
            .addCase(getFund.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getFund.fulfilled, (state, action) => {
                state.fundData = action.payload.data
                state.loading = false
            })
            .addCase(getFund.rejected, (state, action) => {
                state.loading = false
                state.fundData = []
            })
    }
})

export default fundSlice.reducer;