import React from 'react'
import { Navigate, Outlet } from 'react-router-dom'

const AuthGuard = () => {
    if (localStorage.getItem('token') && localStorage.getItem('role') == "admin") {
        return <Navigate to="/dashboard"/>
    } else if(localStorage.getItem('token') && localStorage.getItem('role') == "branch"){
        return <Navigate to="/add-fund"/>
    }else{
        localStorage.removeItem("user_id")
        localStorage.removeItem("role")
        localStorage.removeItem("name")
        localStorage.removeItem("token")
        localStorage.removeItem("email")
        return <Outlet />
    }
}

export default AuthGuard
