import { createSlice } from "@reduxjs/toolkit";
import { getBranch } from "../actions/location.action";
import { addBranch, deleteBranch } from "../actions/branch.action";


const branchSlice = createSlice({
    name: "branch",
    initialState: {
        loading: false,
        branchData: [],
        total: 0
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranch.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getBranch.fulfilled, (state, action) => {
                state.branchData = action.payload.data.branch
                state.total = action.payload.data.totalRecords
                state.loading = false
            })
            .addCase(getBranch.rejected, (state, action) => {
                state.loading = false
                state.branchData = []
            })
            .addCase(addBranch.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addBranch.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(addBranch.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(deleteBranch.pending, (state, action) => {
                state.loading = true
            })
            .addCase(deleteBranch.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(deleteBranch.rejected, (state, action) => {
                state.loading = false
            })
    }
})

export default branchSlice.reducer;