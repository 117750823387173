import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import Button from "../components/common/Button/Button";
import DataTable from "../components/common/Tabel/DataTabel";
import AddAssignment from "../components/common/Modal/AddAssignment";
import useColumns from "../components/hooks/useColumns";
import { useNavigate } from "react-router-dom";
import { options } from "../data/data";
import { useSelector } from "react-redux";
import GenericTable from "../components/custom/GenericTable";
import { getAssignment } from "../redux/actions/assignment.action";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";

const Assignment = () => {
  const navigate = useNavigate();
  const [activeButton, setActiveButton] = useState("pending");
  const [activeTab, setActiveTab] = useState("cargo");
  const [limitVal, setLimitVal] = useState(options[1]);
  const [assignmentModal, setAssignmentModal] = useState({
    open: false,
    data: null,
  });
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();

  const { total, loading, assignmentData } = useSelector(
    (state) => state.assignment
  );

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleView = (row) => {
    navigate(`/assignment/${row._id}`);
  };
  useEffect(() => {
    setActiveTab((prev) => prev);
  }, [activeButton]);
  const { Assignmentcolumns } = useColumns({ handleView });

  return (
    <>
      <Layout title="Assignments">
        <div className="flex w-full justify-between items-center mt-2">
          <div className="flex max-sm:flex-wrap gap-5 px-4">
            <Button
              className={`py-2 rounded-[10px] font-medium text-lg w-[130px] ${
                activeButton === "pending" ? "bg-blue-500 text-white" : ""
              }`}
              text="Pending"
              type={activeButton === "pending"}
              onClick={() => setActiveButton("pending")}
            />

            <Button
              className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${
                activeButton === "running" ? "bg-blue-500 text-white" : ""
              }`}
              text="Running"
              type={activeButton === "running"}
              onClick={() => setActiveButton("running")}
            />
            <Button
              className={`py-2 rounded-[10px] font-medium text-lg w-[170px] ${
                activeButton === "completed" ? "bg-blue-500 text-white" : ""
              }`}
              text="Completed"
              type={activeButton === "completed"}
              onClick={() => setActiveButton("completed")}
            />
          </div>

          <Button
            type={true}
            text={"Create"}
            className="py-2 rounded-[10px] text-lg font-semibold w-[150px] mr-5"
            onClick={() => {
              setAssignmentModal({
                open: true,
                data: null,
              });
            }}
          />
        </div>
        <div className="w-full flex mt-6">
          <div
            className={`w-full cursor-pointer  py-3 ${
              activeTab === "cargo"
                ? "font-semibold text-base border-b-2 border-[#383F90]"
                : "text-base font-normal border-b-2 border-[#E7E6E9] text-[#808080]"
            }`}
            onClick={() => {
              setActiveTab("cargo");
            }}
          >
            Cargo
          </div>
          <div
            className={`w-full cursor-pointer  py-3 ${
              activeTab === "air_cargo"
                ? "font-semibold text-base border-b-2 border-[#383F90]"
                : "text-base font-normal border-b-2 border-[#E7E6E9] text-[#808080]"
            }`}
            onClick={() => {
              setActiveTab("air_cargo");
            }}
          >
            Air Cargo
          </div>
        </div>
        <div>
          {activeTab == "cargo" ? (
            <GenericTable
              data={assignmentData}
              extra={{
                shipping_type: "cargo",
                status: activeButton,
              }}
              total={total}
              loading={loading}
              params={activeTab}
              getData={getAssignment}
              params2={activeButton}
              limitVal={limitVal}
              setLimitVal={setLimitVal}
              search={search}
              columns={Assignmentcolumns}
              height="h-[65vh]"
              message="No Assignment Data"
            />
          ) : (
            <GenericTable
              data={assignmentData}
              extra={{
                shipping_type: "air_cargo",
                status: activeButton,
              }}
              total={total}
              loading={loading}
              params2={activeButton}
              params={activeTab}
              getData={getAssignment}
              limitVal={limitVal}
              setLimitVal={setLimitVal}
              search={search}
              columns={Assignmentcolumns}
              height="h-[65vh]"
              message=" Assignment Not Found"
            />
          )}

          <AddAssignment
            data={assignmentModal}
            activeTab={activeTab}
            activeButton={activeButton}
            limit={limitVal}
            setData={setAssignmentModal}
          />
        </div>
      </Layout>
    </>
  );
};

export default Assignment;
