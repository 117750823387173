import { createSlice } from "@reduxjs/toolkit";
import { getAdminNotificationList, getDashboard } from "../actions/dashboard.action";


const dashboardSlice = createSlice({
    name: "dashboard",
    initialState: {
        loading: false,
        total: 0,
        dashboardData: [],
        notificationData: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getDashboard.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getDashboard.fulfilled, (state, action) => {
                state.total = action.payload.data.totalRecords
                state.dashboardData = action.payload.data.orders
                state.loading = false
            })
            .addCase(getDashboard.rejected, (state, action) => {
                state.total = 0
                state.loading = false
                state.dashboardData = []
            })
        builder.addCase(getAdminNotificationList.pending, (state, action) => {
            state.loading = true
        }).addCase(getAdminNotificationList.fulfilled, (state, action) => {
            state.notificationData = action?.payload?.data
            state.loading = false
        }).addCase(getAdminNotificationList.rejected, (state, action) => {
            state.loading = false
        })
    }
})

export default dashboardSlice.reducer;

