import { useEffect, useState } from "react";
import { motion, useAnimation } from "framer-motion";
import InputWithIcon from "../components/common/Form/InputWithIcon";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/common/Button/Button";
import PasswordInput from "../components/common/Form/PasswordInput";
import { imageUrl } from "../config/url";
import { useFormik } from "formik";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { toast } from "react-toastify";
import TextField from "../components/custom/TextField";
import FormProvider from "../components/common/Form/FormProvider";
import { useForm } from "react-hook-form";
import { LoginSchema } from "../config/schema";
import PasswordField from "../components/custom/PasswordField";
import ButtonLoading from "../components/custom/ButtonLoading";
import { useDispatch, useSelector } from "react-redux";
import { login } from "../redux/actions/auth.action";
import md5 from "md5";

const Login = () => {
  const navigate = useNavigate();
  const [showForm, setShowForm] = useState(false);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const cartLogoControls = useAnimation();
  const formControls = useAnimation();
  const dispatch = useDispatch();
  const defaultValues = {
    email: "",
    password: "",
  };
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(LoginSchema),
  });
  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    dispatch(
      login({
        email: values.email,
        password: md5(values.password),
        // password:values?.password,
      })  
    )
      .unwrap()
      .then(() => {
        if (localStorage.getItem("role") == "admin") {
          navigate("/dashboard");
        } else if (localStorage.getItem("role") == "branch") {
          navigate("/add-fund");
        } else {
          localStorage.removeItem("user_id");
          localStorage.removeItem("role");
          localStorage.removeItem("name");
          localStorage.removeItem("token");
          localStorage.removeItem("email");
          localStorage.removeItem("city");
          window.location = imageUrl;
        }
      })
      .catch((error) => {});
  };

  const { loading } = useSelector((state) => state.auth);

  useEffect(() => {
    const animateSequence = async () => {
      await cartLogoControls.start({ opacity: 1 });
      await new Promise((resolve) => setTimeout(resolve, 500));
      await cartLogoControls.start({ opacity: 0, transition: { duration: 1 } });
      setShowForm(true);
      formControls.start({ opacity: 1, transition: { duration: 1 } });
    };

    animateSequence();
  }, [cartLogoControls, formControls]);

  return (
    <div className="w-full min-h-screen h-full flex justify-center items-center bg-mainBG overflow-hidden">
      {/* <ToastContainer /> */}

      {!showForm && (
        <motion.div
          className="absolute flex justify-center items-center w-full h-full "
          initial={{ opacity: 0 }}
          animate={cartLogoControls}
        >
          <img
            className="w-full max-w-[200px] h-auto "
            src={`${imageUrl}images/CartLogo.png`}
            alt="cart-logo"
          />
        </motion.div>
      )}

      {showForm && (
        <motion.div
          className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full min-h-screen  opacity-0"
          initial={{ opacity: 1 }}
          animate={formControls}
        >
          <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
            <div className="overlay">
              <img
                className=" light-purple-filter"
                src={`${imageUrl}images/handPhone.png`}
                alt="hand-logo"
              />
            </div>
            {/* <div className="px-5">
              <h1 className="max-md:text-3xl text-4xl font-bold text-[#fff] text-center">
                lorem ipsum dummy text
              </h1>
              <p className="max-md:text-lg text-2xl mt-2 text-[#fff] font-normal text-center lg:w-[492px]">
                Lorem Ipsum is simply dummy text of the printing and typesetting
                industry.
              </p>
            </div> */}
          </div>
          <div className="w-full max-md:px-5 flex flex-col items-center justify-center bg-white h-screen ">
            <div className="w-full">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <h2 className="text-mainBG text-3xl md:text-1xl  font-semibold text-center">
                  Sign In to Pika
                </h2>
                <p className="text-lg mt-4 font-medium text-textHeading text-center">
                  Please sign in to continue using our app
                </p>

                <div className="mt-8 flex flex-col gap-y-7  md:px-20 lg:px-32 2xl:px-56">
                  <TextField
                    name="email"
                    placeholder={"Email"}
                    icon={
                      <img src={`${imageUrl}icons/EmailIcon.svg`} alt="email" />
                    }
                  />

                  <PasswordField
                    placeholder="Password"
                    name="password"
                    icon={
                      <img
                        src={`${imageUrl}icons/Password.svg`}
                        alt="password"
                      />
                    }
                  />
                  <Link
                    to={"/forget_password"}
                    className=" text-base font-medium text-mainBG text-end"
                  >
                    Forgot Password?
                  </Link>
                  <Button
                    text={loading ? <ButtonLoading /> : "Sign In"}
                    buttonType={"submit"}
                    className="py-4 rounded-[10px] flex justify-center font-medium text-xl"
                    type={true}
                    disabled={loading}
                  ></Button>
                </div>
              </FormProvider>
            </div>
          </div>
        </motion.div>
      )}
    </div>
  );
};

export default Login;
