import React, { useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import { IoCloudUploadOutline } from "react-icons/io5";
import Button from "../Button/Button";
import FormProvider from "../Form/FormProvider";
import InputField from "../../custom/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import { AddBranchSchema } from "../../../config/schema";
import { useDispatch, useSelector } from "react-redux";
import { addBranch, getBranch } from "../../../redux/actions/branch.action";
import { toFormData } from "axios";
import ButtonLoading from "../../custom/ButtonLoading";
import PasswordInput from "../../custom/PasswordInput";

const AddBranch = ({ data, setData }) => {
  const dispatch = useDispatch();
  const defaultValues = useMemo(
    () => ({
      name: "",
      city: "",
      ccode: "",
      phone: "",
      password: "",
      confirm_password: "",
    }),
    []
  );
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(AddBranchSchema),
  });
  const { loading } = useSelector((state) => state.branch);
  const { handleSubmit, reset } = methods;
  const onSubmit = (values) => {
    if (!profile) {
      toast.error("Profile image is required");
      return 0;
    } else {
      const { confirm_password, ...rest } = values;
      dispatch(
        addBranch(
          toFormData({
            ...rest,
            profile,
            user_id: localStorage.getItem("user_id"),
          })
        )
      )
        .unwrap()
        .then(() => {
          onClose();
          dispatch(
            getBranch({
              user_id: localStorage.getItem("user_id"),
            })
          );
        })
        .catch(() => {});
    }
  };
  const [profile, setProfile] = useState(null);
  const onClose = () => {
    setData({
      open: false,
      data: null,
    });
    reset(defaultValues);
    setProfile(null);
  };

  return (
    <>
      <Dialog open={data.open} onOpenChange={onClose}>
        <DialogContent onOpenAutoFocus={(e) => e.preventDefault()}>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">Add Branch</h1>
              <div
                className="bg-[#F8FAFC] cursor-pointer w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={onClose}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
              <div className="px-4 max-h-[500px] overflow-auto noScrollbar">
                <p className="font-bold text-sm">Photo</p>
                {/* <div className="flex  items-center gap-x-6">
                  <div class="flex  items-center justify-start mt-5">
                    <label class="w-32 h-32 flex flex-col items-center justify-center  bg-[#F8FAFC] text-blue rounded-lg  cursor-pointer">
                      <IoCloudUploadOutline className="text-2xl font-bolder text-[#2563EB]" />
                      <span class="mt-2 text-xs font-semibold text-[#2563EB]">
                        Upload Image
                      </span>
                      <input onChange={(e) => setProfile(e.target.files[0])} type="file" class="hidden" />
                    </label>
                  </div>
                  <div className="">
                    {
                      profile && typeof profile == "object" && <img className="w-36" src={URL.createObjectURL(profile)} />
                    }
                  </div>
                </div> */}
                {!profile ? (
                  <label class="w-32 h-32 flex flex-col items-center justify-center  bg-[#F8FAFC] text-blue rounded-lg  cursor-pointer">
                    <IoCloudUploadOutline className="text-2xl font-bolder text-[#2563EB]" />
                    <span class="mt-2 text-xs font-semibold text-[#2563EB]">
                      Upload Image
                    </span>
                    <input
                      type="file"
                      class="hidden"
                      name="profile"
                      accept="image/png, image/gif, image/jpeg"
                      // onChange={(e) => {
                      //   setProfile(e.target.files[0]);
                      //   formik.setFieldValue(
                      //     "profile",
                      //     e.currentTarget.files[0]
                      //   );
                      // }}
                      onChange={(e) => setProfile(e.target.files[0])}
                    />
                  </label>
                ) : (
                  <img
                    src={URL.createObjectURL(profile)}
                    className="w-[130px] h-[130px] object-cover rounded-md"
                  />
                )}
                <div className="space-y-4 mt-3 mb-4">
                  <InputField
                    label="Name"
                    name="name"
                    placeholder="Name"
                    type={"text"}
                  />

                  <InputField
                    label="Email"
                    name="email"
                    placeholder="Email"
                    type={"text"}
                  />
                  <InputField
                    name="ccode"
                    label="Code"
                    placeholder="code"
                    type={"text"}
                  />
                  <InputField
                    name="phone"
                    label="Phone Number"
                    placeholder="Phone number"
                    type={"text"}
                  />

                  <InputField
                    name="city"
                    label="Branch Location"
                    placeholder="Location"
                    type={"text"}
                  />
                  <PasswordInput
                    name="password"
                    label="Password"
                    className="w-full border py-3 px-3 rounded-xl	border-[#E2E8F0]"
                    placeholder="Password"
                  />
                  <PasswordInput
                    name="confirm_password"
                    label="Confirm Password"
                    className="w-full border py-3 px-3 rounded-xl	border-[#E2E8F0]"
                    placeholder="Confirm password"
                  />

                  <Button
                    text={loading ? <ButtonLoading /> : "Add"}
                    buttonType="submit"
                    disabled={loading}
                    className="py-3 flex justify-center items-center rounded-lg w-full"
                    type={true}
                  />
                </div>
              </div>
            </FormProvider>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddBranch;
