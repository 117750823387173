import { createSlice } from "@reduxjs/toolkit";
import { rejectOrApprovedProduct, submitOrder } from "../actions/product.action";


const productSlice = createSlice({
    name: "product",
    initialState: {
        loading: false
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(rejectOrApprovedProduct.pending, (state, action) => {
                state.loading = true
            })
            .addCase(rejectOrApprovedProduct.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(rejectOrApprovedProduct.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(submitOrder.pending, (state, action) => {
                state.loading = true
            })
            .addCase(submitOrder.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(submitOrder.rejected, (state, action) => {
                state.loading = false
            })

    }
})

export default productSlice.reducer;