import React from "react";
import { IoRadioButtonOff } from "react-icons/io5";
import { IoMdRadioButtonOn } from "react-icons/io";

const RadioButton = ({ label, checked, onChange, className }) => {
  return (
    <label
      className={`flex items-center cursor-pointer ${className}`}
      onClick={onChange}
    >
      <input
        type="radio"
        checked={checked}
        onChange={onChange}
        className="hidden"
      />

      <div className="flex justify-between items-center w-full">
        <span className="text-base font-normal">{label}</span>
        <div className="">
          {checked ? (
            <IoMdRadioButtonOn className="text-xl text-[#383F90]" />
          ) : (
            <IoRadioButtonOff className="text-xl text-[#383F90]" />
          )}
        </div>
      </div>
    </label>
  );
};

export default RadioButton;
