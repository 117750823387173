import { createContext, useContext, useState } from "react";

const SerachContext = createContext();

export const SearchProvider = ({ children }) => {
  const [searchQuery, setSearchQuery] = useState("");
  return (
    <SerachContext.Provider value={{ searchQuery, setSearchQuery }}>
      {children}
    </SerachContext.Provider>
  );
};

export const SearchDataChange = () => useContext(SerachContext);
