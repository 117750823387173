import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../config/api";
import { endPoints } from "../../config";

const getDashboard = createAsyncThunk('GET_DASHBOARD', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.DASHBOARD.LIST, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const getAdminNotificationList = createAsyncThunk("GET_NOTIFICATION", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.DASHBOARD.NOTIFICATION_LIST, values);
        return data
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})


export { getDashboard, getAdminNotificationList } 