import React, { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { BsEye, BsEyeSlash } from "react-icons/bs";

const PasswordField = ({ placeholder, name, icon }) => {
    const [isPasswordVisible, setIsPasswordVisible] = useState(false);
    const { control, formState } = useFormContext()
    return (
        <div>
            <div className="items-center justify-center w-full border rounded-[15px] border-borderColor">
                <div className="relative">
                    <div className="absolute inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        {icon}
                    </div>
                    <Controller
                        name={name}
                        control={control}
                        render={({ field }) => (
                            <input
                                {...field}
                                type={isPasswordVisible ? "text" : "password"}
                                className={`block w-full px-4 py-5 ps-12 text-sm  rounded-[15px] bg-inputBG ${formState.errors?.[name]?.message ? 'text-red-500 focus:outline-red-500' : 'text-gray-900 focus:outline-black'}`}
                                placeholder={placeholder}
                            />
                        )}
                    />
                    <button
                        type="button"
                        className="absolute inset-y-0 right-0 flex items-center pr-3 cursor-pointer"
                        onClick={() => setIsPasswordVisible(!isPasswordVisible)}
                    >
                        <BsEyeSlash
                            className={`
              "text-[#808080]"
              ${isPasswordVisible ? "w-0 h-0" : "w-6 h-6"}
            `}
                        />
                        <BsEye
                            className={`
              "text-[#808080]"
             ${isPasswordVisible ? "w-6 h-6" : "w-0 h-0"} 
            `}
                        />
                    </button>
                </div>
            </div>
            {formState.errors?.[name]?.message && <div className="flex text-red-500 w-full justify-start px-3 mt-2"><span className="text-left">{formState.errors?.[name]?.message}</span></div>}
        </div>
    );
};

export default PasswordField;
