import { configureStore } from "@reduxjs/toolkit";
import authSlice from "./slices/auth.slice";
import dashboardSlice from "./slices/dashboard.slice";
import reviewSlice from "./slices/review.slice";
import driverSlice from "./slices/driver.slice";
import userSlice from "./slices/user.slice";
import assignmentSlice from "./slices/assignment.slice";
import categorySlice from "./slices/category.slice";
import productSlice from "./slices/product.slice";
import locationSlice from "./slices/location.slice";
import orderSlice from "./slices/order.slice";
import branchSlice from "./slices/branch.slice";
import fundSlice from "./slices/fund.slice";
import amoutSlice from "./slices/amout.slice";
import { getAmountRequest } from "./actions/amount.action";

const store = configureStore({
        reducer: {
                auth: authSlice,
                dashboard: dashboardSlice,
                review: reviewSlice,
                driver: driverSlice,
                user: userSlice,
                assignment: assignmentSlice,
                category: categorySlice,
                product: productSlice,
                location: locationSlice,
                order: orderSlice,
                branch: branchSlice,
                fund: fundSlice,
                amount: amoutSlice
        }
})

export default store;