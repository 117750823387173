import React from "react";

const InputWithLabel = ({
  label,
  name,
  placeholder,
  type,
  className = "",
  onChange,
  onBlur,
  error,
  touched,
  value,
}) => {
  return (
    <div className="flex flex-col space-y-4">
      <label className="text-sm font-semibold text-[#0F172A]">{label}</label>
      <div>
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          className={`w-full border py-3 px-3 rounded-xl text-[#808080] placeholder:text-[#808080]	border-[#E2E8F0] outline-none ${className}`}
          onChange={onChange}
          onBlur={onBlur}
          touched={touched}
          onWheel={(e) => { type === "number" && e.target.blur(); }}
        />
        {error && touched ? (
          <div className="text-red-400 text-sm mt-1">{error}</div>
        ) : null}
      </div>
    </div>
  );
};

export default InputWithLabel;
