import { Controller, useFormContext } from 'react-hook-form';

const Select = ({ name, options, label, placeholder,disabled=false }) => {
    const { control, setValue, formState } = useFormContext();

    return (
        <div>
            <div className="flex flex-col space-y-4">
                <label className="text-sm font-semibold text-[#0F172A]">{label}</label>
                <Controller
                    control={control}
                    name={name}
                    render={({ field}) => {
                        return(
                        <select disabled={disabled} className='w-full border py-3 px-3 rounded-xl text-base text-[#808080] focus:outline-none 	border-borderColor font-medium' value={field.value} onChange={(e) => setValue(name, e.target.value,{
                            shouldDirty:true,
                            shouldValidate:true
                        })}>
                            <option  value={""}>{placeholder}</option>
                            {options.map((option, index) => (
                                <option  key={index} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </select>
                    )}}
                />
            </div>
            {formState.errors?.[name]?.message && <div className="flex text-red-500 w-full text-sm justify-start px-3 mt-2"><span className="text-left">{formState.errors?.[name]?.message}</span></div>}
        </div>
    );
};

export default Select;