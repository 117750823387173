import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";

const AssignmentOverviewModal = ({ data, setData }) => {
  const onClose = () => {
    setData({
      open: false,
      data: null,
    });
  };

  return (
    <>
      <Dialog open={data.open} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">Overview</h1>
              <div
                className="bg-[#F8FAFC] cursor-pointer w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={onClose}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="px-4">
              <table class="table-auto w-full mt-2 mb-2">
                <thead className="bg-[#F6F6F6] ">
                  <tr className="text-textColor text-sm  font-medium  ">
                    <th className="text-sm font-medium py-3 text-start px-4">
                      Category
                    </th>
                    <th className="text-sm font-medium py-3 text-start ">
                      Quantity
                    </th>
                    <th className="text-sm font-medium py-3 text-start pr-4">
                      Price
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {data?.data?.map((val) => {
                    return (
                      <tr className="text-black text-sm text-center ">
                        <td className="text-sm font-semibold py-3 text-start px-4 w-[400px]">
                          {val?.category?.name}
                        </td>
                        <td className="text-sm font-semibold py-3 pl-6 text-start w-[110px]">
                          {val?.total_quantity}
                        </td>
                        <td className="text-sm font-semibold text-start w-[90px]">
                          IQD {val?.total_amount}
                        </td>
                      </tr>
                    );
                  })}
                  <tr className="text-black text-sm text-center border-t border-[#F1F5F9]">
                    <td className="text-base font-bold text-[#383F90] py-3 text-start px-4">
                      Total
                    </td>
                    <td className="text-base font-bold text-[#383F90] py-3 pl-6 text-start"></td>
                    <td className="text-base font-bold text-[#383F90] pr-4 text-start pl-0.5">
                      IQD{" "}
                      {data?.data?.reduce(
                        (acc, current) => acc + current.total_amount,
                        0
                      )}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AssignmentOverviewModal;
