import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import useColumns from "../components/hooks/useColumns";
import DataTable from "../components/common/Tabel/DataTabel";
import Button from "../components/common/Button/Button";
import DeleteDriver from "../components/common/Modal/DeleteDriver";
import AddDriver from "../components/common/Modal/AddDriver";
import { imageUrl } from "../config/url";
import { useDispatch, useSelector } from "react-redux";
import { getDriver } from "../redux/actions/auth.action";
import { options } from "../data/data";
import GenericTable from "../components/custom/GenericTable";
import { getDriverData } from "../redux/actions/driver.action";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";

const Driver = () => {
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const { loading, drivers, total } = useSelector((state) => state.driver);
  const [openModal, setOpenModal] = useState(false);

  const [openDaletemodal, setOpenDeleteModal] = useState({
    open: false,
    data: null,
  });
  const handleView = (data) => {
    setOpenDeleteModal({
      open: true,
      data: data,
    });
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const { driverColumns } = useColumns({ handleView });
  return (
    <>
      <Layout role="branchAdmin" title="Driver">
        <div className="w-full flex justify-end px-4">
          <Button
            type={true}
            text="Add Driver"
            className="py-3 rounded-lg w-[160px]"
            onClick={() => {
              setOpenModal(true);
            }}
          />
        </div>
        {/* <DataTable columns={driverColumns} data={driverData} /> */}
        <GenericTable
          limitVal={limitVal}
          getData={getDriverData}
          setLimitVal={setLimitVal}
          total={total}
          loading={loading}
          search={search}
          data={drivers}
          columns={driverColumns}
          message="Driver Not Found"
        />
        <DeleteDriver
          openModal={openDaletemodal}
          closeModal={() => {
            setOpenDeleteModal({ open: false, data: null });
          }}
        />
        <AddDriver
          openModal={openModal}
          closeModal={() => {
            setOpenModal(false);
          }}
        />
      </Layout>
    </>
  );
};

export default Driver;
