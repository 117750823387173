import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import { endPoints } from "../../config";
import api from "../../config/api";

const login = createAsyncThunk('LOGIN', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.AUTH.LOGIN, values);
        toast.success(data.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
      
        return rejectWithValue(error)
    }
})

const forgot_password = createAsyncThunk('FORGOT_PASSWORD', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.AUTH.FORGOT_PASSWORD, values);
        localStorage.setItem('forgot_email', values.email)
        toast.success(data.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const verifyOTP = createAsyncThunk('VERIFY_OTP', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.AUTH.VERIFY_OTP, { ...values, email: window.localStorage.getItem('forgot_email') });
        toast.success(data.ResponseMsg)
        localStorage.removeItem('forgot_email', values.email)
        localStorage.setItem('forgot_id', data.data.user_id)
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const resetPassword = createAsyncThunk('RESET_PASSWORD', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.AUTH.RESET_PASSWORD, { ...values, user_id: window.localStorage.getItem('forgot_id') });
        toast.success(data.ResponseMsg)
        localStorage.removeItem('forgot_id')
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

// for branch admin flow

const getDriver = createAsyncThunk('GET_DRIVER', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.LIST, values);
        // toast.success(data.ResponseMsg)
        // localStorage.removeItem('forgot_id')
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const addFund = createAsyncThunk("ADD_FUND", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ADD_FUND, values)
        toast.success(data.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error);
    }
})
export { login, forgot_password, verifyOTP, resetPassword, getDriver, addFund }