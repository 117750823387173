import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api";
import { toast } from "react-toastify";
import { endPoints } from "../../config";

const getBranch = createAsyncThunk('GET_BRANCH', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH.LIST, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

export { getBranch }