import React, { useEffect } from "react";
import { Dialog, DialogContent, DialogHeader } from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { getReview } from "../../../redux/actions/review.action";

import Loader from "../../custom/Loader";
import ViewOrder from "../../../sections/order/ViewOrder";
import { removeReviewData } from "../../../redux/slices/review.slice";

const ViewOrdersModal = ({ data, setData, limit }) => {
  const onClose = () => {
    setData({
      open: false,
      data: null,
    });
  };
  const dispatch = useDispatch();
  const { loading, reviewData } = useSelector((state) => state.review);
  const title = {
    approved: "View Orders",
    confirm: "Confirmed Orders",
    warehouse: "View",
  };
  useEffect(() => {
    if (data?.data?._id) {
      dispatch(
        getReview({
          user_id: localStorage.getItem("user_id"),
          order_id: data?.data?._id,
        })
      );
    }
    return () => {
      dispatch(removeReviewData());
    };
  }, [data?.data?._id]);

  return (
    <>
      <Dialog open={data.open} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">
                {title[reviewData?.order?.status] ? title[reviewData?.order?.status] : "View Orders"}
              </h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={onClose}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          {Object.keys(reviewData).length > 0 && !loading ? (
            <ViewOrder
              limit={limit}
              onClose={onClose}
              reviewData={reviewData}
              data={data}
              title={!title[reviewData?.order?.status]}
            />
          ) : (
            <div className="h-[300px] pb-10 flex justify-center items-center">
              {loading ? (
                <Loader />
              ) : (
                <h2 className="text-xl text-black font-semibold">
                  No Reviews Found
                </h2>
              )}
            </div>
          )}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewOrdersModal;
