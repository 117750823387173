import axios from "axios";
import { API_BASE_URL, APP_BASE_PATH } from ".";


const api = axios.create({
    baseURL: API_BASE_URL
})

api.interceptors.request.use((config) => {
    config.headers['token'] = `${window.localStorage.getItem('token')}`;
    return config;
})

api.interceptors.response.use((response) => {
    const data = response.data;
    if (data.Result == true) {
        return data;
    } else {
        if (data.ResponseCode == 9) {
            localStorage.removeItem('token')
            window.location.href = APP_BASE_PATH
        } else {
            return Promise.reject(data)
        }
    }
}, (error) => {
    const data = error.response.data;

    if (data.ResponseCode == 9) {
        window.location.href = APP_BASE_PATH
    } else {
        return Promise.reject(data)
    }
})

export default api;