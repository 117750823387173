import { Eye, EyeOff } from "lucide-react";
import { useState } from "react";
import { Controller, useFormContext } from "react-hook-form";

const PasswordInput = ({
  label,
  name,
  placeholder,
  className = "",
  ...others
}) => {
  const { control, formState } = useFormContext();
  const [show, setShow] = useState(false)
  return (
    <div>
      <div className="flex flex-col space-y-4">
        <label className="text-sm font-semibold text-[#0F172A]">{label}</label>
        <Controller
          name={name}
          control={control}
          render={({ field }) => (
            <div className="relative">
              <input
                type={show ? "text" : "password"}
                {...field}
                placeholder={placeholder}
                className={`w-full border py-3 pl-3 pr-10 rounded-xl text-[#808080] placeholder:text-[#808080]	border-[#E2E8F0] outline-none ${className}`}
                {...others}
              />
              <button onClick={()=>setShow(!show)} type="button" className="absolute top-3 right-3 ">{show ? <Eye size={20}/> : <EyeOff size={20}/>}</button>
            </div>
          )}
        />
      </div>
      {formState.errors?.[name]?.message && (
        <div className="flex text-red-500 w-full text-sm justify-start px-3 mt-2">
          <span className="text-left">{formState.errors?.[name]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default PasswordInput;
