import React, { useEffect } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import { imageUrl } from "../../../config/url";
import { useDispatch, useSelector } from "react-redux";
import { getProductFeedback } from "../../../redux/actions/assignment.action";

import StarIcon from "../../icons/StarIcon";

const RatingModal = ({ openModal, closeModal }) => {
  const dispatch = useDispatch();
  const { loading, feedback } = useSelector((state) => state.assignment);

  useEffect(() => {
    openModal.open == true &&
      dispatch(
        getProductFeedback({
          user_id: localStorage.getItem("user_id"),
          feedback_id: openModal.data.feedback,
        })
      );
  }, [openModal, dispatch]);

  return (
    <>
      <Dialog open={openModal?.open} onOpenChange={closeModal}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">Review</h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="px-4 pb-5 space-y-5">
              <div className="space-y-5 mt-3">
                <p className="text-lg	font-semibold text-center">
                  {openModal?.data?.user?.name}
                </p>
                <div className="flex flex-col justify-center items-center space-y-2">
                  {feedback?.image && (
                    <img
                      src={feedback?.image}
                      alt="rating"
                      className="h-[450px] w-full object-cover"
                    />
                  )}

                  <div className="flex gap-2 justify-center">
                    {[...Array(5)].map((_, index) => (
                      <StarIcon
                        key={index}
                        index={index}
                        rating={feedback?.rate}
                      />
                    ))}
                  </div>
                  <p className="text-base font-semibold text-textColor text-center">
                    {feedback?.rate}
                  </p>
                </div>
              </div>
              {feedback?.feedback && (
                <div className="border border-[#E2E8F0] rounded-lg	 px-3 py-4">
                  <p>{feedback?.feedback}</p>
                </div>
              )}
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RatingModal;
