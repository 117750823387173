import React from "react";

const OTPInput = ({ id, prevElement, name, nextElement, onChange }) => {
  const focusNextInput = (e) => {
    const { value } = e.target;
    if (value.length === 0 && prevElement) {
      document.getElementById(prevElement).focus();
    } else if (value.length === 1 && nextElement) {
      document.getElementById(nextElement).focus();
    }
  };

  return (
    <input
      className="w-full flex-1 max-h-[70px] py-3 text-xl bg-inputBG  rounded-xl border border-borderColor focus-visible:ring-0 focus-visible:ring-offset-0 text-center placeholder:text-center"
      id={id}
      maxLength={1}
      name={name}
      onKeyUp={focusNextInput}
      onChange={onChange}
    />
  );
};

export default OTPInput;
