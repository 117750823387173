import React, { useState } from "react";
import Button from "../components/common/Button/Button";
import Layout from "../components/common/Layouts/Layout";
import DataTable from "../components/common/Tabel/DataTabel";
import useColumns from "../components/hooks/useColumns";
import ViewOrdersModal from "../components/common/Modal/ViewOrdersModal";
import { options } from "../data/data";
import { useDispatch, useSelector } from "react-redux";
import GenericTable from "../components/custom/GenericTable";
import {
  getAmountRequest,
  updateRequestAmount,
} from "../redux/actions/amount.action";

const CloseRequest = () => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("pending");
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const [statusData, setStatusData] = useState({
    status: activeButton,
  });
  const [openApprove, setOpenApprove] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [openWareHouse, setOpenWareHouse] = useState(false);

  const { loading, total, amountData } = useSelector((state) => state.amount);
  const handleView = () => {
    setOpenApprove(true);
  };

  const handleConfirmView = () => {
    setOpenConfirm(true);
  };

  const handleWareHouseView = () => {
    setOpenWareHouse(true);
  };

  const handleAccept = (data) => {
    const payload = {
      status: "accepted",
      settle_id: data?._id,
      user_id: localStorage.getItem("user_id"),
    };
    dispatch(updateRequestAmount(payload))
      .unwrap()
      .then(() => {
        dispatch(
          getAmountRequest({
            status: statusData?.status,
            user_id: localStorage.getItem("user_id"),
            page: 1,
            limit: 10,
          })
        );
      });
  };
  const handleReject = (data) => {
    const payload = {
      status: "rejected",
      settle_id: data?._id,
      user_id: localStorage.getItem("user_id"),
    };
    dispatch(updateRequestAmount(payload))
      .unwrap()
      .then(() => {
        dispatch(
          getAmountRequest({
            status: statusData?.status,
            user_id: localStorage.getItem("user_id"),
            page: 1,
            limit: 10,
          })
        );
      });
  };

  const { AmountAccepted, AmountRejected, AmountPending } = useColumns({
    handleView,
    handleConfirmView,
    handleWareHouseView,
    handleAccept,
    handleReject,
  });

  const renderContent = () => {
    switch (activeButton) {
      case "accepted":
        return (
          <GenericTable
            columns={AmountAccepted}
            data={amountData}
            getData={getAmountRequest}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            extra={statusData}
            height="h-[72vh]"
            message="Accepted Requests Not Found"
          />
        );
      case "rejected":
        return (
          <GenericTable
            columns={AmountRejected}
            data={amountData}
            getData={getAmountRequest}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            extra={statusData}
            height="h-[72vh]"
            message="Rejected Requests Not Found"
          />
        );
      case "pending":
        return (
          <GenericTable
            columns={AmountPending}
            data={amountData}
            getData={getAmountRequest}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            extra={statusData}
            height="h-[72vh]"
            message="Pending Requests Not Found"
          />
        );
      default:
        return (
          <GenericTable
            columns={AmountAccepted}
            data={amountData}
            getData={getAmountRequest}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={activeButton}
            extra={statusData}
            height="h-[72vh]"
            message="Accepted Requests Not Found"
          />
        );
    }
  };

  return (
    <>
      <Layout title="Amount Request" role="branchAdmin">
        <div className="flex justify-end max-sm:flex-wrap gap-5 mt-3 px-4">
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[130px] ${
              activeButton === "pending" ? "bg-blue-500 text-white" : ""
            }`}
            text="Pending"
            type={activeButton === "pending"}
            onClick={() => {
              setActiveButton("pending");
              setStatusData({ status: "pending" });
            }}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${
              activeButton === "accepted" ? "bg-blue-500 text-white" : ""
            }`}
            text="Accepted"
            type={activeButton === "accepted"}
            onClick={() => {
              setActiveButton("accepted");
              setStatusData({ status: "accepted" });
            }}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[170px] ${
              activeButton === "rejected" ? "bg-blue-500 text-white" : ""
            }`}
            text="Rejected"
            type={activeButton === "rejected"}
            onClick={() => {
              setActiveButton("rejected");
              setStatusData({ status: "rejected" });
            }}
          />
        </div>
        {renderContent()}
        {/* <ViewOrdersModal
          openModal={openApprove}
          closeModal={() => {
            setOpenApprove(false);
          }}
          type="approve"
          title="View Orders"
        />
        <ViewOrdersModal
          openModal={openConfirm}
          closeModal={() => {
            setOpenConfirm(false);
          }}
          type="confirm"
          title="Confirmed Orders"
        />
        <ViewOrdersModal
          openModal={openWareHouse}
          closeModal={() => {
            setOpenWareHouse(false);
          }}
          type="warehouse"
          title="View"
        /> */}
      </Layout>
    </>
  );
};

export default CloseRequest;
