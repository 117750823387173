import React, { useState } from "react";
import moment from "moment";
import { useSwipeable } from "react-swipeable";
import { IoIosArrowBack } from "react-icons/io";
import { IoIosArrowForward } from "react-icons/io";

const DatePicker = () => {
  const [month, setMonth] = useState(moment());
  const [selected, setSelected] = useState(moment().startOf("day"));

  const previous = () => {
    setMonth(month.clone().subtract(1, "month"));
  };

  const next = () => {
    setMonth(month.clone().add(1, "month"));
  };

  const select = (day) => {
    setSelected(day.date);
    setMonth(day.date.clone()); // Close the modal when a date is selected
  };

  const handlers = useSwipeable({
    onSwipedLeft: () => next(),
    onSwipedRight: () => previous(),
  });

  const renderWeeks = () => {
    let weeks = [];
    let done = false;
    let date = month
      .clone()
      .startOf("month")
      .add("w" - 1)
      .day("Sunday");
    let count = 0;
    let monthIndex = date.month();

    while (!done) {
      weeks.push(
        <Week
          key={date}
          date={date.clone()}
          month={month}
          select={select}
          selected={selected}
        />
      );

      date.add(1, "w");

      done = count++ > 2 && monthIndex !== date.month();
      monthIndex = date.month();
    }

    return weeks;
  };

  const renderMonthLabel = () => {
    return (
      <span className="flex-1 text-left pl-2 pb-4 text-xl font-semibold border-b border-[#F1F5F9]">
        {month.format("MMMM YYYY")}
      </span>
    );
  };

  return (
    <section className="bg-white  rounded-lg p-2 w-full" {...handlers}>
      <header className="text-secondary">
        <div className="flex justify-between mb-4">
          {renderMonthLabel()}
          <div className="space-x-5">
            <button
              className="cursor-pointer w-[26px] h-[26px] rounded-[6px] bg-[#F0F1F2] flex items-center justify-center"
              onClick={previous}
            >
              <svg
                width="6"
                height="11"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M0.219668 6.03033C-0.0732225 5.73745 -0.0732225 5.26255 0.219668 4.96968L4.71968 0.469668C5.01255 0.176777 5.48745 0.176777 5.78033 0.469668C6.0732 0.762565 6.0732 1.23743 5.78033 1.53033L1.81065 5.5L5.78033 9.46968C6.0732 9.76255 6.0732 10.2375 5.78033 10.5303C5.48745 10.8232 5.01255 10.8232 4.71968 10.5303L0.219668 6.03033Z"
                  fill="#202020"
                />
              </svg>
            </button>

            <button
              className="cursor-pointer w-[26px] h-[26px] rounded-[6px] bg-[#F0F1F2] flex items-center justify-center"
              onClick={next}
            >
              <svg
                width="6"
                height="11"
                viewBox="0 0 6 11"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  clip-rule="evenodd"
                  d="M5.78033 6.03033C6.07322 5.73745 6.07322 5.26255 5.78033 4.96968L1.28032 0.469668C0.98745 0.176777 0.51255 0.176777 0.219675 0.469668C-0.0732002 0.762565 -0.0732002 1.23743 0.219675 1.53033L4.18935 5.5L0.219675 9.46968C-0.0732002 9.76255 -0.0732002 10.2375 0.219675 10.5303C0.51255 10.8232 0.98745 10.8232 1.28032 10.5303L5.78033 6.03033Z"
                  fill="#202020"
                />
              </svg>
            </button>
          </div>
        </div>
        <DayNames />
      </header>
      {renderWeeks()}
    </section>
  );
};

function DayNames() {
  return (
    <div className="flex justify-around text-secondary font-bold mb-2">
      <span className="w-8">Su</span>
      <span className="w-8">Mo</span>
      <span className="w-8">Tu</span>
      <span className="w-8">We</span>
      <span className="w-8">Th</span>
      <span className="w-8">Fr</span>
      <span className="w-8">Sa</span>
    </div>
  );
}

function Week({ date, month, select, selected }) {
  let days = [];

  for (let i = 0; i < 7; i++) {
    const day = {
      name: date.format("dd").substring(0, 1),
      number: date.date(),
      isCurrentMonth: date.month() === month.month(),
      isToday: date.isSame(new Date(), "day"),
      date: date,
    };

    days.push(
      <Day
        key={date.toString()}
        day={day}
        selected={selected}
        select={select}
      />
    );

    date = date.clone().add(1, "day");
  }

  return <div className="flex justify-around mb-2">{days}</div>;
}

function Day({ day, select, selected }) {
  const { date, isCurrentMonth, isToday, number } = day;

  const handleClick = () => {
    select(day);
  };

  const isSelected = date.isSame(selected, "day");

  return (
    <span
      className={`w-8 h-8  flex items-center justify-center cursor-pointer transition-all duration-200 rounded-full 
      ${isToday ? "bg-[#383F90] text-white" : ""} 
      ${isCurrentMonth ? "" : "text-gray-400"} 
      ${isSelected ? "bg-[#383F90] text-white shadow-xl" : ""}
      `}
      onClick={handleClick}
    >
      {number}
    </span>
  );
}

export default DatePicker;
