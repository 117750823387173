import { useDispatch, useSelector } from "react-redux";
import Button from "../../components/common/Button/Button";
import {
  orderAddToAssignment,
  readyToWarehouse,
} from "../../redux/actions/review.action";

import { getOrders } from "../../redux/actions/order.action";
import RadioButton from "../../components/common/Form/RadioButton";
import { imageUrl } from "../../config/url";
import Select from "../../components/custom/Select";
import FormProvider from "../../components/common/Form/FormProvider";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { ConfirmOrder } from "../../config/schema";
import { useEffect, useState } from "react";
import {
  getAssignment,
  getPendingAssignment,
} from "../../redux/actions/assignment.action";
import {
  removeAssignment,
  removePendingAssignment,
} from "../../redux/slices/assignment.slice";
import ButtonLoading from "../../components/custom/ButtonLoading";

const ViewOrder = ({ reviewData, data, onClose, limit, title }) => {
  const nationalCharge = 0;
  const type = reviewData?.order?.status;
  const [assignment, setAssignment] = useState([]);
  const { assignmentData, pendingAssignment } = useSelector(
    (state) => state.assignment
  );
  const { loading } = useSelector((state) => state.review);
  const dispatch = useDispatch();
  const transportationCharge =
    reviewData?.order?.shipping_type == "cargo"
      ? reviewData.order.cargo
      : reviewData?.order?.shipping_type == "air_cargo"
        ? reviewData.order.air_cargo
        : 0;
  const quantity = reviewData?.products
    ?.filter((item) => item.status == "accepted")
    ?.reduce((acc, current) => acc + current.quantity, 0);
  const servicecharge = reviewData?.products
    ?.filter((item) => item?.status == "accepted")
    ?.reduce((acc, current) => acc + current.service_charge, 0);
  const totalCost = reviewData?.products
    ?.filter((item) => item.status == "accepted")
    ?.reduce((acc, current) => acc + current.quantity * current.price, 0);
  const readyToWarehouseHandler = () => {
    dispatch(
      readyToWarehouse({
        user_id: localStorage.getItem("user_id"),
        order_id: reviewData?.order?._id,
      })
    )
      .unwrap()
      .then(() => {
        onClose();
        dispatch(
          getOrders({
            user_id: localStorage.getItem("user_id"),
            status: reviewData?.order?.status,
            page: 1,
            limit: limit.value,
          })
        );
      })
      .catch(() => { });
  };

  useEffect(() => {
    if (data?.data?._id && type == "warehouse") {
      dispatch(
        getPendingAssignment({
          user_id: localStorage.getItem("user_id"),
          shipping_type: reviewData?.order?.shipping_type,
        })
      );
    }
    return () => {
      dispatch(removePendingAssignment());
    };
  }, [data?.data?._id]);

  useEffect(() => {
    if (pendingAssignment.length > 0) {
      setAssignment(
        pendingAssignment.map((item) => ({
          value: item._id,
          label: item.name,
        }))
      );
    }
  }, [pendingAssignment]);

  const methods = useForm({
    resolver: yupResolver(ConfirmOrder),
  });
  const { handleSubmit } = methods;

  const onSubmit = (values) => {
    dispatch(
      orderAddToAssignment({
        user_id: localStorage.getItem("user_id"),
        order_id: reviewData?.order?._id,
        assignment_id: values.assignment_id,
      })
    )
      .unwrap()
      .then(() => {
        onClose();
        dispatch(
          getOrders({
            user_id: localStorage.getItem("user_id"),
            status: reviewData?.order?.status,
            page: 1,
            limit: limit.value,
          })
        );
      })
      .catch(() => { });
  };
  return (
    <div>
      <div className="px-4 max-h-[500px] overflow-y-auto noScrollbar">
        <div className="flex gap-x-5 items-center justify-between w-full">
          <div className="w-[40%]">
            <p className="font-semibold text-lg	">{data?.data?.username}</p>
            <p className="text-base font-semibold text-[#808080]">
              {data?.data?.city}
            </p>
            {title && <p className={`text-lg font-medium capitalize`}>{reviewData?.order?.status}</p>}
          </div>
          <div className="w-[60%]">

            {reviewData?.order?.address && (
              <p className="text-black font-semibold text-right">
                {reviewData?.order?.address?.line_1} {","}{" "}
                {reviewData?.order?.address?.line_2} {","}{" "}
                {reviewData?.order?.address?.city} {"-"}{" "}
                {reviewData?.order?.address?.pincode} {","}{" "}
                {reviewData?.order?.address?.country}
              </p>
            )}
          </div>
        </div>
        <div>
          <table class="table-auto w-full mt-5">
            <thead className="bg-[#F6F6F6] ">
              <tr className="text-textColor text-sm  font-medium  ">
                <th className="text-sm font-medium py-3 text-start px-4">
                  Item
                </th>
                <th className="text-sm font-medium py-3 text-start ">
                  Quantity
                </th>
                <th className="text-sm font-medium py-3 text-end pr-4">
                  Price Per Qty
                </th>
              </tr>
            </thead>
            <tbody className="">
              {reviewData?.products
                .filter((item) => item.status == "accepted")
                .map((item, index) => (
                  <tr key={index} className="text-black text-sm text-center ">
                    <td className="text-sm font-semibold py-3 text-start px-4">
                      {item.product_name}
                    </td>
                    <td className="text-sm font-semibold py-3 pl-6 text-start">
                      {item?.quantity}
                    </td>
                    <td className="text-sm font-semibold pr-4 text-end">
                      IQD {item?.price}
                    </td>
                  </tr>
                ))}
              {/* const totalQuantity = data.products.reduce((acc, current) => acc + current.quantity, 0); */}

              <tr className="text-black text-sm text-center ">
                <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
                  Subtotal
                </td>
                <td className="text-base font-medium text-[#808080] py-3 pl-6 text-start">
                  {quantity}
                </td>
                <td className="text-base font-medium text-[#808080] pr-4 text-end">
                  IQD {totalCost}
                </td>
              </tr>
              <tr className="text-black text-sm text-center ">
                <td className="text-base font-medium capitalize text-[#808080] py-3 text-start px-4">
                  Transportation Charges{" "}
                  {type == "confirm" && `(${reviewData?.order?.shipping_type})`}
                </td>
                <td className="text-base font-medium text-[#808080] py-3 pl-6 text-start"></td>
                <td className="text-base font-medium text-[#808080] pr-4 text-end">
                  IQD {transportationCharge}
                </td>
              </tr>
              <tr className="text-black text-sm text-center ">
                <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
                  National Charges
                </td>
                <td className="text-base font-medium text-[#808080] py-3 pl-6 text-end"></td>
                <td className="text-base font-medium text-[#808080] pr-4 text-end">
                  IQD {nationalCharge}
                </td>
              </tr>
              <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
                <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
                  Service Charges
                </td>
                <td className="text-base font-medium text-[#808080] py-3 pl-6 text-start"></td>
                <td className="text-base font-medium text-[#808080] pr-4 text-end">
                  IQD {servicecharge}
                </td>
              </tr>

              <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
                <td className="text-base font-bold  py-3 text-start px-4">
                  Total
                </td>
                <td className="text-base font-bold   py-3 pl-6 text-start"></td>
                <td className="text-base font-bold   pr-4 text-end">
                  IQD {transportationCharge + totalCost + servicecharge}
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {type === "warehouse" ? (
          <div className="mt-5 space-y-5">
            <p className="text-sm font-semibold text-[#0F172A] ">
              Shipping Type
            </p>
            <div className="sm:grid sm:grid-cols-2 max-sm:flex max-sm:flex-col gap-4 mx-auto max-w-5xl ">
              <div
                className={`relative bg-white rounded-lg px-5 py-3 cursor-pointer  border-2 border-[#383F90]`}
              >
                <RadioButton
                  label={
                    <div className="flex w-full justify-between items-center">
                      <div className="flex gap-3 items-center">
                        <div className="flex items-center justify-center w-[50px] h-[50px] rounded-xl bg-[#F7F7FA]">
                          <img
                            src={
                              reviewData?.order?.shipping_type == "cargo"
                                ? `${imageUrl}icons/Tempo.png`
                                : `${imageUrl}icons/Airplane.png`
                            }
                            alt="not found"
                          />
                        </div>
                        <span className="plan-type capitalize text-base font-normal">
                          {reviewData?.order?.shipping_type == "cargo"
                            ? "Cargo"
                            : "Air Cargo"}
                        </span>
                      </div>
                    </div>
                  }
                  checked={true}
                  className="w-full"
                />
              </div>
            </div>
            <div className="">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div className="py-5">
                  <Select
                    label="Assignment"
                    name="assignment_id"
                    placeholder={"Select Assignment"}
                    options={assignment}
                  />
                </div>
                <div className="py-5">
                  {type == "warehouse" && (
                    <Button
                      text={loading ? <ButtonLoading /> : "Confirm & Send"}
                      type={true}
                      buttonType="submit"
                      className="py-3 rounded-xl w-full"
                    />
                  )}
                </div>
              </FormProvider>
            </div>
          </div>
        ) : (
          <div className="py-5">
            {type == "approved" && (
              <Button
                text="Ok"
                type={true}
                buttonType="button"
                className="py-3 rounded-xl w-full"
                onClick={onClose}
              />
            )}

            {type == "confirm" && (
              <Button
                text={loading ? <ButtonLoading /> : "Ready to Warehouse"}
                disabled={loading}
                type={true}
                buttonType="button"
                className="py-3 flex items-center justify-center  rounded-xl w-full"
                onClick={readyToWarehouseHandler}
              />
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ViewOrder;
