import moment from "moment";
import React from "react";
import { USER_ICON } from "../../config/images";
import { convertToLocal, dateFormat } from "../../config/helper";
const useColumns = (fns) => {
  const handleView = fns.handleView;
  const handleViewConfirm = fns.handleViewConfirm;
  const handleViewWareHouse = fns.handleViewWareHouse;
  const handleAccept = fns.handleAccept;
  const handleReject = fns.handleReject;
  const handleDeliveryView = fns.handleDeliveryView;
  const status = fns.status;

  const Orders = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "username",
      header: () => "Username",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "total_quantity",
      header: () => <p className="text-center">Quantity</p>,
      cell: (info) => <p className="text-center"> {info.getValue()}</p>,
    },
    {
      accessorKey: "city",
      header: () => "City",
      cell: (info) => info.getValue(),
    },

    {
      id: "view",
      header: () => <p className="text-center">Action</p>,
      cell: (info) => (
        <div className="flex justify-center">
          <button
            onClick={() => fns.handleView(info.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            View
          </button>
        </div>
      ),
    },
  ];

  const Confirm = [
    {
      accessorKey: "orderNumber",
      header: () => "Order Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "username",
      header: () => "Username",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "quantity",
      header: () => "Quantity",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "city",
      header: () => "City",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "note",
      header: () => "Note",
      cell: (info) => info.getValue(),
    },
    {
      id: "view",
      header: "Action",
      cell: (info) => (
        <button
          onClick={handleViewConfirm}
          className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
        >
          View
        </button>
      ),
    },
  ];

  const WareHouse = [
    {
      accessorKey: "orderNumber",
      header: () => "Order Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "username",
      header: () => "Username",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "quantity",
      header: () => "Quantity",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "city",
      header: () => "City",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "note",
      header: () => "Note",
      cell: (info) => info.getValue(),
    },
    {
      id: "view",
      header: "Action",
      cell: (info) => (
        <button
          onClick={handleViewWareHouse}
          className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
        >
          View
        </button>
      ),
    },
  ];

  const Assignmentcolumns = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "name",
      header: () => "Assignment Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "datetime",
      header: () => "Date & Time",
      cell: (info) => <p>{dateFormat(convertToLocal(info?.getValue()))}</p>,
    },
    {
      accessorKey: "branch",
      header: () => "Branch Name",
      cell: (info) => info.getValue()?.city,
    },
    {
      id: "view",
      header: <p className="w-full text-center ">Action</p>,
      cell: (info) => (
        <div className="flex justify-center">
          <button
            onClick={() => fns.handleView(info.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            View
          </button>
        </div>
      ),
    },
  ];
  const Transitcolumns = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "name",
      header: () => "Assignment Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "datetime",
      header: () => "Date & Time",
      cell: (info) => <p>{dateFormat(info.getValue())}</p>,
    },
    {
      accessorKey: "branch",
      header: () => "Branch Name",
      cell: (info) => info.getValue()?.name,
    },
    {
      accessorKey: "status",
      header: <p className="text-center w-full">Status</p>,
      cell: (info) => (
        <div className="flex justify-center">
          {info.getValue() == "completed" ? (
            <div className="bg-[#ebfbf5] capitalize text-[#2fc78f] text-sm font-medium px-5 py-2 rounded-lg">
              {info.getValue()}
            </div>
          ) : info.getValue() == "pending" ? (
            <div className=" bg-[#FFFBEB] capitalize text-[#FEB941] text font-medium px-5 py-2 rounded-lg">
              {info.getValue()}
            </div>
          ) : (
            <div className="bg-[#fff0f0] capitalize text-[#ed4f4f] text-sm font-medium px-5 py-2 rounded-lg">
              {info.getValue()}
            </div>
          )}
        </div>
      ),
    },
  ];
  const DashBoardcolumns = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "username",
      header: () => "Username",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "total_quantity",
      header: () => <p className="text-center">Quantity</p>,
      cell: (info) => <p className="text-center"> {info.getValue()}</p>,
    },
    {
      accessorKey: "city",
      header: () => "City",
      cell: (info) => info.getValue(),
    },
    // {
    //   accessorKey: "note",
    //   header: () => "Note",
    //   cell: (info) => info.getValue(),
    // },
    {
      id: "action",
      header: () => <p className="text-center">Action</p>,
      cell: (info) => (
        <div className="flex justify-center items-center">
          <button
            onClick={() => fns.handleReview(info.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            Review
          </button>
        </div>
      ),
    },
  ];

  const BranchColumns = [
    {
      accessorKey: "profile",
      header: () => "",
      cell: (info) => (
        <img
          src={info.getValue() ? info.getValue() : USER_ICON}
          alt="Profile"
          className="w-[48px] h-[48px] rounded-full"
        />
      ),
    },
    {
      accessorKey: "name",
      header: () => "Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "city",
      header: () => "Branch Location",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "email",
      header: () => "Email",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "phone",
      header: () => "Phone Number",
      cell: (info) => "+" + info.row.original.ccode + " " + info.getValue(),
    },
    {
      id: "action",
      header: () => <p className="text-center">Action</p>,
      cell: (info) => (
        <div className="flex justify-center">
          <button
            onClick={() => fns?.handleDelete(info.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];

  const addFundsColumns = [
    {
      accessorKey: "_id",
      header: () => "Unique ID",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "name",
      header: () => "Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "email",
      header: () => "Email",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "wallet",
      header: () => "Available Balance",
      cell: (info) => <p> IQD {info.getValue()}</p>,
    },
    {
      id: "addFunds",
      header: <p className="text-center w-full">Action</p>,
      cell: (props) => (
        <div className="flex justify-center">
          <button
            onClick={() => handleView(props.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            Add Funds
          </button>
        </div>
      ),
    },
  ];

  const branchOrdersColumns = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "name",
      header: () => "Assignment Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "datetime",
      header: () => "Date & Time",
      cell: (info) => (
        // <p> {moment(info.getValue()).format("DD-MM-YYYY HH:MM A")}</p>
        <p> {dateFormat(convertToLocal(info.getValue()))}</p>
      ),
    },
    // {
    //   accessorKey: "branchName",
    //   header: () => "Branch Name",
    //   cell: (info) => info.getValue(),
    // },
    {
      id: "status",
      header: <p className="text-center w-full">Action</p>,
      // cell: (info) => (
      //   <button
      //     onClick={() => handleView(info.row.original)}
      //     className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
      //   >
      //     Viewd
      //   </button>
      // ),
      cell: (props, info) => {
        // const userId = props.row.original.user_id;
        return (
          <div className="flex gap-x-4 justify-center items-center">
            {props.row.original.status === "pending" && (
              <>
                <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAccept(props.row.original);
                  }}
                  className="bg-[#6FC451] hover:bg-[#6FC451] font-medium text-white px-4 text-sm md:text-base w-max h-10 md:h-10 rounded-lg"
                >
                  Accept
                </button>
                {/* <button
                  onClick={(e) => {
                    e.stopPropagation();
                    handleAccept(props.row.original);
                  }}
                  className="bg-[#C45151] hover:bg-[#C45151] font-medium text-white px-4 text-sm md:text-base w-max h-10 md:h-10 rounded-lg"
                >
                  Reject
                </button> */}
              </>
            )}
            {props.row.original.status !== "pending" && (
              <button
                onClick={() => handleView(props.row.original)}
                className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
              >
                View
              </button>
            )}
            {/* {props.getValue() === "Rejected" && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                }}
                className="bg-[#EECBCB] hover:bg-[#EECBCB] font-normal px-6 text-sm md:text-base w-max h-10 md:h-10 text-[#C45151]"
              >
                Rejected
              </button>
            )} */}
          </div>
        );
      },
    },
  ];

  const deliveryColumns = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "user",
      header: () => "Name",
      cell: (info) => info.getValue()?.name,
    },
    {
      accessorKey: "user",
      header: () => "Phone Number",
      cell: (info) => (
        <p>
          +{info.getValue()?.ccode} {info.getValue()?.phone}
        </p>
      ),
    },
    // {
    //   accessorKey: "branchName",
    //   header: () => "Branch Name",
    //   cell: (info) => info.getValue(),
    // },
    {
      accessorKey: "address",
      header: () => "Delivery Address",
      cell: (info) => info.getValue(),
    },
    { 
      id: "assign",
      header: () => <p className="text-center">Action</p>,
      cell: (info) => (
        <div className="flex justify-center">
          <button
            onClick={() => handleDeliveryView(info.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            View
          </button>
        </div>
      ),
    },
  ];
  const productsColumns = [
    {
      accessorKey: "_id",
      header: () => "Product Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "user",
      header: () => "User Name",
      cell: (info) => info.getValue()?.name,
    },
    {
      accessorKey: "user",
      header: () => "Phone Number",
      cell: (info) => (
        <p>
          +{info.getValue()?.ccode} {info.getValue()?.phone}
        </p>
      ),
    },

    {
      accessorKey: "address",
      header: () => "Delivery Address",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "status",
      header: () => "Status",
      cell: (info) => info.getValue(),
    },

    {
      id: "assign",
      header: <p className="text-center w-full">Action</p>,
      cell: (info) => {
        const status = info.row.original.status;
        if (status === "transit") {
          return (
            <div className="flex justify-center">
              <button
                onClick={() => handleView(info.row.original)}
                className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
              >
                Assign
              </button>
            </div>
          );
        }
        return null;
      },
    },
  ];
  const completedColumns = [
    {
      accessorKey: "_id",
      header: () => "Order Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "user",
      header: () => "Name",
      cell: (info) => info.getValue()?.name,
    },
    {
      accessorKey: "quantity",
      header: () => <p className="text-center w-full">Quantity</p>,
      cell: (info) => <p className="text-center"> {info.getValue()}</p>,
    },
    // {
    //   accessorKey: "branchName",
    //   header: () => "Branch Name",
    //   cell: (info) => info.getValue(),
    // },
    {
      accessorKey: "address",
      header: () => "Delivery Address",
      cell: (info) => info.getValue(),
    },
    // {
    //   accessorKey: "note",
    //   header: () => "Note",
    //   cell: (info) => info.getValue(),
    // },
    {
      id: "assign",
      header: () => <p className="text-center">Action</p>,
      cell: (info) => (
        <div className="flex  items-center">
          {info.row.original.feedback && (
            <button
              onClick={() => {
                handleViewConfirm(info.row.original);
              }}
              className="bg-mainBG text-white text-sm font-medium px-5 py-2 mr-5 rounded-lg"
            >
              View Feedback
            </button>
          )}

          <button
            onClick={() => {
              handleView(info.row.original);
            }}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            Proof of Delivery
          </button>
        </div>
      ),
      size: 400,
    },
  ];

  const driverColumns = [
    {
      accessorKey: "profile",
      header: () => "",
      cell: (info) => (
        <img
          src={info.getValue()}
          alt="Profile"
          className="w-[48px] h-[48px] rounded-full"
        />
      ),
    },
    {
      accessorKey: "name",
      header: () => "Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "email",
      header: () => "Email",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "phone",
      header: () => "Phone Number",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "city",
      header: () => "City",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "licence_number",
      header: () => "License Number",
      cell: (info) => info.getValue(),
    },
    {
      id: "delete",
      header: <p className="text-center w-full">Action</p>,
      cell: (props) => (
        <div className="flex justify-center">
          <button
            onClick={() => handleView(props.row.original)}
            className="bg-mainBG text-white text-sm font-medium px-5 py-2 rounded-lg"
          >
            Delete
          </button>
        </div>
      ),
    },
  ];
  const Accepted = [
    {
      accessorKey: "name",
      header: () => "Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "date",
      header: () => "Date",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "status",
      header: () => "Status",
      align: "right",
      cell: (info) => (
        <div className="flex ">
          <div
            // onClick={() => handleView(info)}
            className="bg-transparent text-[#2fc78f] text font-semibold  py-2 rounded-lg"
          >
            Accepted
          </div>
        </div>
      ),
    },
  ];
  const Rejected = [
    {
      accessorKey: "name",
      header: () => "Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "date",
      header: () => "Date",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "status",
      header: () => "Status",
      cell: (info) => (
        <button
          //onClick={() => handleView(info)}
          className="bg-transparent text-[#ed4f4f] text font-semibold  py-2 rounded-lg"
        >
          Rejected
        </button>
      ),
    },
  ];
  const Pending = [
    {
      accessorKey: "name",
      header: () => "Name",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "date",
      header: () => "Date",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => info.getValue(),
    },
    {
      id: "status",
      header: () => "Status",
      cell: (info) => (
        <div className="flex gap-x-8">
          {status ? (
            <>
              <div
                // onClick={() => handleView(info)}
                className="bg-[#ebfbf5] text-[#2fc78f] text-sm font-medium px-5 py-2 rounded-lg"
              >
                Accept
              </div>
              <div
                //onClick={() => handleView(info)}
                className="bg-[#fff0f0] text-[#ed4f4f] text-sm font-medium px-5 py-2 rounded-lg"
              >
                Reject
              </div>
            </>
          ) : (
            <div
              //onClick={() => handleView(info)}
              className="bg-transparent text-[#FEB941] text font-semibold  py-2 rounded-lg"
            >
              Pending
            </div>
          )}
        </div>
      ),
    },
  ];

  const AmountPending = [
    {
      accessorKey: "_id",
      header: () => "Request Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => <p>IQD {info.getValue()}</p>,
    },
    {
      accessorKey: "start_date",
      header: () => "Start Date",
      cell: (info) => <p> {moment(info.getValue()).format("DD-MM-YYYY")}</p>,
    },
    {
      accessorKey: "end_date",
      header: () => "End Date",
      cell: (info) => <p> {moment(info.getValue()).format("DD-MM-YYYY")}</p>,
    },
    {
      id: "status",
      header: () => <p className="text-center w-full">Status</p>,
      cell: (props, info) => (
        <div className="flex gap-x-8 justify-center">
          <>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleAccept(props.row.original);
              }}
              className="bg-[#6FC451] hover:bg-[#6FC451] font-medium text-white px-4 text-sm md:text-base w-max h-10 md:h-10 rounded-lg"
            >
              Accept
            </button>
            <button
              onClick={(e) => {
                e.stopPropagation();
                handleReject(props.row.original);
              }}
              className="bg-[#C45151] hover:bg-[#C45151] font-medium text-white px-4 text-sm md:text-base w-max h-10 md:h-10 rounded-lg"
            >
              Reject
            </button>
          </>
        </div>
      ),
    },
  ];

  const AmountAccepted = [
    {
      accessorKey: "_id",
      header: () => "Request Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => <p>IQD {info.getValue()}</p>,
    },
    {
      accessorKey: "start_date",
      header: () => "Start Date",
      cell: (info) => <p> {moment(info.getValue()).format("DD-MM-YYYY")}</p>,
    },
    {
      accessorKey: "end_date",
      header: () => "End Date",
      cell: (info) => <p> {moment(info.getValue()).format("DD-MM-YYYY")}</p>,
    },
    {
      id: "status",
      header: () => <p className="text-center w-full">Status</p>,
      cell: (info) => (
        <div className="flex justify-center">
          <div
            // onClick={() => handleView(info)}
            className="bg-[#ebfbf5] text-[#2fc78f] text-sm font-medium px-5 py-2 rounded-lg"
          >
            Accept
          </div>
        </div>
      ),
    },
  ];

  const AmountRejected = [
    {
      accessorKey: "_id",
      header: () => "Request Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => <p> IQD {info.getValue()}</p>,
    },
    {
      accessorKey: "start_date",
      header: () => "Start Date",
      cell: (info) => <p> {moment(info.getValue()).format("DD-MM-YYYY")}</p>,
    },
    {
      accessorKey: "end_date",
      header: () => "End Date",
      cell: (info) => <p> {moment(info.getValue()).format("DD-MM-YYYY")}</p>,
    },
    {
      id: "status",
      header: () => <p className="text-center w-full"> Status</p>,
      cell: (info) => (
        <div className="flex justify-center">
          <button
            //onClick={() => handleView(info)}
            className="bg-[#fff0f0] text-[#ed4f4f] text-sm font-medium px-5 py-2 rounded-lg"
          >
            Reject
          </button>
        </div>
      ),
    },
  ];

  const FundCollectionRequest = [
    {
      accessorKey: "_id",
      header: () => "Request Number",
      cell: (info) => <p> {info.getValue()}</p>,
    },
    {
      accessorKey: "amount",
      header: () => "Amount",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "start_date",
      header: () => "Start Date",
      cell: (info) => info.getValue(),
    },
    {
      accessorKey: "end_date",
      header: () => "End Date",
      cell: (info) => info.getValue(),
    },
    {
      id: "status",
      header: () => "Status",
      cell: (props, info) => (
        <div className="flex ">
          {props.row.original.status === "pending" && (
            <div className="bg-transparent text-[#FEB941] text font-semibold  py-2 rounded-lg">
              Pending
            </div>
          )}
          {props.row.original.status === "rejected" && (
            <div className="bg-transparent text-[#ed4f4f] text font-semibold  py-2 rounded-lg">
              Rejected
            </div>
          )}
          {props.row.original.status === "accepted" && (
            <div className="bg-transparent text-[#2fc78f] text font-semibold  py-2 rounded-lg">
              Accepted
            </div>
          )}
        </div>
      ),
    },
  ];
  return {
    Orders,
    Pending,
    Accepted,
    Rejected,
    Confirm,
    WareHouse,
    Assignmentcolumns,
    DashBoardcolumns,
    BranchColumns,
    addFundsColumns,
    branchOrdersColumns,
    deliveryColumns,
    completedColumns,
    driverColumns,
    productsColumns,
    Transitcolumns,
    AmountPending,
    AmountRejected,
    AmountAccepted,
    FundCollectionRequest,
  };
};

export default useColumns;
