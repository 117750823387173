import React from "react";
import PasswordInput from "../components/common/Form/PasswordInput";
import { Link, useNavigate } from "react-router-dom";
import Button from "../components/common/Button/Button";
import { GoArrowLeft } from "react-icons/go";
import { imageUrl } from "../config/url";
import PasswordField from "../components/custom/PasswordField";
import FormProvider from "../components/common/Form/FormProvider";
import { useDispatch, useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { resetPassword } from "../redux/actions/auth.action";
import md5 from "md5";
import { ResetPasswordSchema } from "../config/schema";
import ButtonLoading from "../components/custom/ButtonLoading";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth)
  const defaultValues = {
    new_password: "",
    confirm_password: ""
  }
  const methods = useForm({
    defaultValues,
    resolver: yupResolver(ResetPasswordSchema)
  })
  const navigate = useNavigate()
  const { handleSubmit, formState: { errors } } = methods
  const onSubmit = (values) => {
    dispatch(resetPassword({
      new_password: md5(values.new_password)
    })).unwrap().then(() => {
      navigate('/')
    }).catch((error) => {

    })
  }
  return (
    <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full bg-mainBG">
      <div className=" flex flex-col items-center justify-center sm:py-0 py-10 space-y-10">
        <div>
          <img
            // className="w-[344px] h-[436px] "
            src={`${imageUrl}images/phoneSecurity.png`}
            alt="hand-logo"
          />
        </div>
        {/* <div className="px-5">
          <h1 className="text-3xl xs:text-[34px] max-md:leading-[44px] md:text-4xl font-bold text-[#fff] text-center">
            lorem ipsum dummy text
          </h1>
          <p className="text-lg md:text-2xl mt-2 text-[#fff] font-normal text-center">
            Lorem Ipsum is simply dummy text of the printing and typesetting
            industry.
          </p>
        </div> */}
      </div>
      <div className="w-full max-md:px-5 flex flex-col items-center justify-center bg-white h-screen ">
        <div className="flex justify-center mb-6">
          <img src={`${imageUrl}images/securityIcon.png`} alt="lock" />
        </div>
        <div className="w-full">
          <h2 className="text-mainBG text-[26px] xs:text-3xl md:text-1xl  font-semibold text-center">
            Set New Password
          </h2>
          <div className="flex mt-3.5 justify-center">
            <p className="xs:text-lg max-xs:leading-[26px] font-medium text-textHeading text-center md:w-[373px] w-full">
              Your new password must be different from previously used password.
            </p>
          </div>

          <div className="mt-8 flex  justify-center items-center   w-full">
            <div className="flex flex-col space-y-8 md:w-[360px] w-full">
              <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
                <div className="flex flex-col space-y-8 md:w-[360px] w-full">
                  <PasswordField
                    placeholder="New Password"
                    name="new_password"
                    icon={<img src={`${imageUrl}icons/Password.svg`} alt="password" />}
                  />
                  <PasswordField
                    placeholder="Confirm Password"
                    name="confirm_password"
                    icon={<img src={`${imageUrl}icons/Password.svg`} alt="password" />}
                  />
                  <Button
                    text={loading ? <ButtonLoading /> : "Reset Password"}
                    className="py-3 rounded-[10px] font-medium text-xl"
                    type={true}
                    buttonType="submit"
                    disabled={loading}
                  ></Button>
                </div>
              </FormProvider>
            </div>
          </div>
          <div className="flex justify-center mt-20 ">
            <Link className="flex items-center gap-3 text-[#808080] text-lg font-normal" to='/'>
              <GoArrowLeft /> <span>Back to Sign In</span>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;
