import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const TextAreaField = ({ label, placeholder, name }) => {
  const { control, formState } = useFormContext()
  return (
    <div className="flex flex-col space-y-4">
      <label className="text-sm font-semibold">{label}</label>
      <Controller
        name={name}
        control={control}
        render={({ field }) => (
          <textarea
            {...field}
            placeholder={placeholder}
            className="w-full border py-3 px-3 rounded-xl h-[140px]	border-[#E2E8F0]"
          />
        )}
      />
      {formState.errors?.[name]?.message && (
        <div className="flex text-red-500 pl-3 w-full text-sm justify-start">
          <span className="text-left">{formState.errors?.[name]?.message}</span>
        </div>
      )}
    </div>
  );
};

export default TextAreaField;
