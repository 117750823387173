import React from "react";
import { IoMdSearch } from "react-icons/io";
import * as Popover from "@radix-ui/react-popover";
import { FiSearch } from "react-icons/fi";

const SearchBar = ({ placeholder, type, onChange }) => {
  return (
    <div className="items-center justify-center w-full border rounded-[15px]  border-borderColor">
      <div className="relative">
        <div className="absolute  inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
          <IoMdSearch className="text-[#64748B]" />
        </div>
        <input
          type={type}
          id="search"
          className="block w-full px-4 py-2.5 ps-10 text-sm text-gray-900 rounded-[15px] bg-inputBG placeholder:text-[#64748B]"
          placeholder={placeholder}
          onChange={onChange}
        />
      </div>
    </div>
  );
};

export default SearchBar;
