import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import DatePickerModal from "../components/common/Modal/DatePickerModal";
import { imageUrl } from "../config/url";
import { FaExternalLinkAlt } from "react-icons/fa";
import DatePicker from "react-datepicker";
import RequestSettelment from "../components/common/Modal/RequestSettelment";
import { setDate } from "date-fns";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getFund } from "../redux/actions/fund.action";
import debounce from "lodash/debounce";
import Loader from "../components/custom/Loader";
import moment from "moment";
import { SearchDataChange } from "../components/hooks/SearchDataContext";

const FundCollection = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [isModalOpen, setIsModalOpen] = useState(false);
  const { loading, fundData } = useSelector((state) => state.fund);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const [requestModal, setRequestModal] = useState({
    open: false,
    data: null,
  });

  const [dateRange, setDateRange] = useState([null, null]);
  const [startDate, endDate] = dateRange;

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  useEffect(() => {
    dispatch(
      getFund({
        user_id: localStorage.getItem("user_id"),
        search: search,
      })
    );
  }, [search]);
  return (
    <>
      <Layout title="Fund Collection">
        <div className="bg-white rounded-xl flex justify-end px-6 py-6">
          <div className="flex gap-3">
            <img src={`${imageUrl}icons/Filter.svg`} alt="filter" />
            <div>
              <DatePicker
                selectsRange={true}
                startDate={startDate}
                endDate={endDate}
                onChange={(update) => {
                  setDateRange(update);
                }}
                placeholderText="Filter"
                //isClearable={true}
              />
            </div>
          </div>
        </div>
        {/* <p className="text-base font-medium text-[#808080]">Filter</p> */}

        {fundData.length > 0 && !loading ? (
          <div className="grid lg:grid-cols-4 sm:grid-cols-2 gap-5 mt-6">
            {fundData.map((item, index) => (
              <div
                key={index}
                onClick={() => navigate(`/fund-collection/${item._id}`)}
                className="bg-white cursor-pointer relative flex gap-3 items-center p-4 rounded-xl"
              >
                <div className="w-[60px] h-[60px] bg-[#ADE1F624]/15	rounded-[15px] flex justify-center items-center ">
                  <svg
                    width="30"
                    height="30"
                    viewBox="0 0 30 30"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <mask
                      id="mask0_4955_944"
                      // style="mask-type:luminance"
                      maskUnits="userSpaceOnUse"
                      x="0"
                      y="0"
                      width="30"
                      height="30"
                    >
                      <path
                        d="M0 1.90735e-06H30V30H0V1.90735e-06Z"
                        fill="white"
                      />
                    </mask>
                    <g mask="url(#mask0_4955_944)">
                      <path
                        d="M17.7485 22.6936C18.6455 22.9926 19.6182 22.5284 19.9502 21.6432C20.3 20.7104 19.8054 19.6736 18.8603 19.3585L11.2228 16.8316C9.59607 16.2892 6.49102 16.1848 4.39453 18.2813"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.39453 18.2812H0.878906V27.0703H4.39453V18.2812Z"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M4.39453 25.3126L12.3632 27.1234C13.5554 27.3963 14.8344 27.5534 16.0574 27.5391C19.2218 27.5021 22.2988 26.4697 24.874 24.6304L28.3849 22.0295C29.2055 21.4435 29.3658 20.2886 28.7358 19.5011C28.1526 18.7722 27.1012 18.6263 26.3416 19.1688L22.5172 21.9939C21.1443 22.9746 19.385 23.2391 17.7844 22.7056L12.3635 20.9181"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M20.7414 11.7427C21.844 12.5697 23.9516 11.9842 23.9516 10.8105C23.9516 8.86484 20.2266 10.1194 20.2266 8.17378C20.2266 6.99482 22.3376 6.4172 23.4367 7.24155"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.0898 13.125V12.1289"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M22.0898 6.85547V5.85938"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                      <path
                        d="M29.1211 9.49219C29.1211 13.3754 25.9731 16.5234 22.0898 16.5234C18.2066 16.5234 15.0586 13.3754 15.0586 9.49219C15.0586 5.60895 18.2066 2.46094 22.0898 2.46094C25.9731 2.46094 29.1211 5.60895 29.1211 9.49219Z"
                        stroke="#2EB6ED"
                        strokeWidth="1.75781"
                        strokeMiterlimit="10"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                      />
                    </g>
                  </svg>
                </div>
                <div>
                  <p className="text-start text-base font-medium text-[#64748B] capitalize">
                    {item?.branch?.name}
                  </p>
                  <p className="text-start text-lg font-semibold ">
                    IQD {item?.totalAmount}
                  </p>
                </div>
                <div
                  onClick={(e) => {
                    e.stopPropagation();
                    setRequestModal({
                      open: true,
                      data: item,
                    });
                  }}
                  className="absolute p-3 right-4"
                >
                  <FaExternalLinkAlt className="cursor-pointer text-[#64748B] size-5" />
                </div>
              </div>
            ))}
          </div>
        ) : (
          <div className="h-[20vh] w-full flex justify-center items-center">
            {loading ? (
              <Loader />
            ) : (
              <h1 className="text-black text-xl font-medium">
                Fund collection not found
              </h1>
            )}
          </div>
        )}
        <DatePickerModal
          openModal={isModalOpen}
          closeModal={() => {
            setIsModalOpen(false);
          }}
        />

        <RequestSettelment
          data={requestModal}
          setData={setRequestModal}
          startDate={dateRange && dateRange[0]}
          endDate={dateRange && dateRange[1]}
        />
      </Layout>
    </>
  );
};

export default FundCollection;
