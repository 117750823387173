import React from "react";
import { Dialog, DialogContent, DialogDescription } from "./ModalComponent";
import DatePicker from "../Form/DatePicker";
import Button from "../Button/Button";

const DatePickerModal = ({ openModal, closeModal }) => {
  return (
    <>
      <Dialog open={openModal} onOpenChange={closeModal}>
        <DialogContent>
          <DialogDescription>
            <div className="w-full px-4 py-5">
              <DatePicker />
              <Button
                type={true}
                text="Ok"
                className="w-full py-3 rounded-lg"
                onClick={closeModal}
              />
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DatePickerModal;
