import React, { useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import BreadCrumb from "../components/common/BreadCrumb";
import Button from "../components/common/Button/Button";
import AssignmentOverviewModal from "../components/common/Modal/AssignmentOverviewModal";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getAssignmentDetails } from "../redux/actions/assignment.action";

import Loader from "../components/custom/Loader";
import { convertToLocal, dateFormat } from "../config/helper";
import { removeAssignmentData } from "../redux/slices/assignment.slice";
import { getReview } from "../redux/actions/review.action";
import { removeReviewData } from "../redux/slices/review.slice";

const AssignmentDetail = () => {
  const dispatch = useDispatch();
  const { id } = useParams();
  const { details, loading } = useSelector((state) => state.assignment);
  const { reviewData, loading: reviewLoading } = useSelector(
    (state) => state.review
  );
  useEffect(() => {
    if (id) {
      dispatch(
        getAssignmentDetails({
          user_id: localStorage.getItem("user_id"),
          assignment_id: id,
        })
      );
    }
    return () => {
      dispatch(removeAssignmentData());
      dispatch(removeReviewData());
    };
  }, [id]);
  const navigate = useNavigate();

  const [overviewModal, setOverviewModal] = useState({
    open: false,
    data: null,
  });

  const [activeOrder, setActiveOrder] = useState(null);

  useEffect(() => {
    if (activeOrder) {
      dispatch(
        getReview({
          user_id: localStorage.getItem("user_id"),
          order_id: activeOrder,
        })
      );
    }
  }, [activeOrder]);

  const handleBackButton = () => {
    navigate(-1);
  };
  return (
    <>
      <Layout title="View Assignment">
        {Object.keys(details).length > 0 && !loading ? (
          <>
            <BreadCrumb onClick={handleBackButton} />
            <div className="w-full bg-white px-6 py-5 rounded-xl mt-6">
              <div className="flex gap-3 sm:flex-row flex-col w-full justify-between  sm:items-center">
                <p className="text-[22px] font-bold text-start">
                  {details?.assignment?.name}
                </p>
                <div className="flex gap-4 text-end">
                  {details?.assignment?.status == "completed" ? (
                    <button className="text-sm py-3 capitalize  w-[100px] rounded-lg font-medium text-[#2fc78f] bg-[#ebfbf5]">
                      {details.assignment.status}
                    </button>
                  ) : (
                    <button className="text-sm py-3 capitalize  w-[100px] rounded-lg font-medium text-[#F6A723] bg-[#FFFBEB]">
                      {details.assignment.status}
                    </button>
                  )}
                  <Button
                    text="Overview"
                    className="text-sm font-bold py-3 rounded-lg w-[140px]"
                    type={true}
                    onClick={() => {
                      setOverviewModal({
                        open: true,
                        data: details?.overview,
                      });
                    }}
                  />
                </div>
              </div>
              <div className="bg-[#FAFBFC] grid lg:grid-cols-4	sm:grid-cols-2 grid-cols-1 gap-3 w-full px-6 py-3 mt-8 rounded-xl ">
                <div className="flex flex-col py-2 items-center justify-center sm:border-r-2 sm:border-b-0 border-b-2 border-[#E2E8F0]">
                  <p className="text-[#94A3B8] text-sm font-medium">
                    Date & Time
                  </p>
                  <p className="text-base font-semibold">
                    {dateFormat(convertToLocal(details?.assignment?.datetime))}
                    {/* {moment(details?.assignment?.datetime).format("DD-MM-YYYY HH:MM A")} */}
                  </p>
                </div>
                <div className="flex flex-col py-2 items-center lg:border-r-2 sm:border-b-0 border-b-2  border-[#E2E8F0]">
                  <p className="text-[#94A3B8] text-sm font-medium">
                    Branch Location
                  </p>
                  <p className="text-base font-semibold">
                    {details?.assignment?.branch?.city}
                  </p>
                </div>
                <div className="flex flex-col py-2 items-center justify-center sm:border-r-2 sm:border-b-0 border-b-2 border-[#E2E8F0]">
                  <p className="text-[#94A3B8] text-sm font-medium">
                    Shipping Type
                  </p>
                  <p className="text-base font-semibold">
                    {details?.assignment?.shipping_type}
                  </p>
                </div>
                <div className="flex py-2 flex-col items-center  ">
                  <p className="text-[#94A3B8] text-sm font-medium">
                    Total Product
                  </p>
                  <p className="text-base font-semibold">
                    {details?.totalProduct}
                  </p>
                </div>
              </div>
            </div>
            <div className="grid sm:grid-cols-3 grid-cols-1  sm:gap-8 mt-6">
              <div className="bg-white rounded-lg py-5 px-3 sm:mb-0 mb-8">
                <p className="text-sm font-semibold text-start mb-2">
                  Order ID
                </p>
                {/* <div className="py-3 px-3 bg-[#EFF0FF]   rounded-lg">
              <p className="text-sm font-medium  text-start">
                SKN1200
              </p>
            </div> */}
                {details.orders &&
                  details.orders.length > 0 &&
                  details.orders.map((val, index) => {
                    return (
                      <div
                        className={`py-3 px-3 cursor-pointer rounded-lg ${
                          activeOrder === val._id ? "bg-[#EFF0FF] " : ""
                        }`}
                        onClick={() => {
                          setActiveOrder(val._id);
                        }}
                      >
                        <p
                          className={`text-sm font-medium text-[#94A3B8] text-start ${
                            activeOrder === val._id
                              ? "text-[#383F90]"
                              : " text-[#94A3B8]"
                          }`}
                        >
                          {val?._id}
                        </p>
                      </div>
                    );
                  })}
              </div>
              <div className="col-span-2 ">
                {reviewData?.products?.length > 0 && !reviewLoading ? (
                  <div className="bg-white rounded-lg p-5 h-auto">
                    <p className="text-sm font-semibold text-start">
                      {reviewData?.order?._id}
                    </p>
                    <table class="table-auto w-full mt-5 overflow-x-auto">
                      <thead className="bg-[#F6F6F6] ">
                        <tr className="text-textColor text-sm  font-medium  ">
                          <th className="text-sm font-medium py-3 text-start px-4">
                            Item
                          </th>
                          <th className="text-sm font-medium py-3 text-start ">
                            Category
                          </th>
                          <th className="text-sm font-medium py-3 text-start ">
                            Quantity
                          </th>
                          <th className="text-sm font-medium py-3 text-start pr-4">
                            Price Per Qty
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        {reviewData.products &&
                          reviewData?.products
                            .filter((item) => item.status == "accepted")
                            .map((val) => {
                              return (
                                <tr className="text-black text-sm text-center ">
                                  <td className="text-base font-medium py-3 text-start px-4 w-[300px]">
                                    {val?.product_name}
                                  </td>
                                  <td className="text-base font-medium py-3 text-[#383F90] w-[110px] text-start">
                                    {val?.category?.name}
                                  </td>
                                  <td className="text-base font-medium py-3 pl-6 text-start w-[110px]">
                                    {val?.quantity}
                                  </td>
                                  <td className="text-base font-medium text-start w-[120px]">
                                  IQD {val?.price}
                                  </td>
                                </tr>
                              );
                            })}
                        <tr className="text-black text-sm text-center border-t border-[#F1F5F9]">
                          <td className="text-base font-bold  py-3 text-start px-4">
                            Total
                          </td>
                          <td className="text-base font-bold   py-3 pl-6 text-start"></td>
                          <td className="text-base font-bold   py-3 pl-6 text-start"></td>
                          <td className="text-base font-bold   text-start">
                          IQD {" "}
                            {reviewData.products.filter((item)=>item.status=="accepted").reduce(
                              (acc, current) =>
                                acc + current.quantity * current.price,
                              0
                            )}
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                ) : reviewLoading ? (
                  <div>
                    <Loader />
                  </div>
                ) : (
                  <div className="py-10 text-lg font-medium">
                    {details.orders.length > 0
                      ? "Choose your order to view details"
                      : "Order does not exist"}
                  </div>
                )}
              </div>
            </div>
            <AssignmentOverviewModal
              data={overviewModal}
              setData={setOverviewModal}
            />
          </>
        ) : loading ? (
          <div className="h-[70vh]">
            <Loader />
          </div>
        ) : (
          <div className="text-center h-[70vh] font-semibold text-2xl">
            No Data Found
          </div>
        )}
      </Layout>
    </>
  );
};

export default AssignmentDetail;
