import React from "react";
import { Controller, useFormContext } from "react-hook-form";

const TextField = ({ icon, placeholder, type, name }) => {
    const { control ,formState } = useFormContext()
    return (
        <div>
            <div className="items-center justify-center w-full border rounded-[15px]  border-borderColor outline-none">

                <div class="relative">
                    <div class="absolute  inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
                        {icon}
                    </div>
                    <Controller
                        control={control}
                        name={name}
                        render={({ field, formState: { errors } }) => (
                            <input
                                type={type}
                                {...field}
                                class={`block w-full px-4 py-5 ps-12 text-sm ${formState.errors?.[name]?.message?'text-red-500 focus:outline-red-500':'text-gray-900 focus:outline-black'}   rounded-[15px] bg-inputBG `}
                                placeholder={placeholder}
                            />
                        )}
                    />
                </div>

            </div>
           {formState.errors?.[name]?.message && <div className="flex text-red-500 w-full justify-start px-3 mt-2"><span className="text-left">{formState.errors?.[name]?.message}</span></div>}
        </div>
    );
};

export default TextField;
