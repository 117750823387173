
import { Navigate, Outlet } from "react-router-dom";
import { toast } from "react-toastify";

const VerifyGuard = () => {
    const forgot_password = window.localStorage.getItem('forgot_email');
    if (forgot_password) {
        return <Outlet />
    }else{
        
        return <Navigate to={"/forget_password"} />
    }
    
    
}

export default VerifyGuard

