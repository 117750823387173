export const API_BASE_URL = process.env.REACT_APP_API_URL;
export const APP_BASE_PATH = process.env.REACT_APP_BASE_PATH;


export const endPoints = {
    AUTH: {
        LOGIN: '/admin/login',
        FORGOT_PASSWORD: "/admin/sendOtp",
        VERIFY_OTP: "/admin/verifyOtp",
        RESET_PASSWORD: '/admin/updatePassword'
    },
    BRANCH_ADMIN: {
        USERLIST: "/branch/getUsers",
        LIST: '/branch/getDrivers',
        ADD_FUND: '/branch/addFund',
        ADD_DRIVER: '/branch/addDriver',
        DELETE_DRIVER: "/branch/removeDriver",
        ASSIGNMENT_LIST: "/branch/getTransitAssignment",
        ASSIGNMENT_ORDER: "/branch/getAssignmentOrders",
        ACCEPT_ASSIGNMENT: "/branch/acceptAssignment",
        STATUS_ORDER_LIST: "/branch/getDelOrCompOrders",
        PRODUCT_FEEDBACK: "/branch/getFeedback",
        PRODUCT_DETAILS: "/branch/getProductDetails",
        ASSIGN_ORDER_TO_DRIVER: "/branch/orderAssignToDriver",
        GET_AMOUNT_REQUEST: "/branch/getAmountRequest",
        UPDATE_AMOUNT_REQUEST: "/branch/updateAmountStatus",
        SETTLE_AMOUNT_REQUEST: "/admin/settleFundAmount",
        ADMIN_AMOUNT_REQUEST: "/admin/getAmountRequest"
    },
    DASHBOARD: {
        LIST: '/admin/getDashboard',
        REVIEW: "/admin/getProductDetails",
        READY_TO_WAREHOUSE: "/admin/readyToWarehouse",
        ORDER_ADD_TO_ASSIGNMENT: "/admin/orderAddToAssignment",
        NOTIFICATION_LIST: '/admin/getNotification'
    },
    CATEGORY: {
        LIST: "/admin/getCategory"
    },
    PRODUCT: {
        REJECTAPPROVED: "/admin/acceptRejectProduct",
        SUBMIT_ORDER: "/admin/submitOrder"
    },
    ASSIGNMENT: {
        LIST: "/admin/getAssignment",
        PENDING_ASSIGNMENT: '/admin/getAssignmentList',
        ADD: "/admin/addAssignment",
        DETAILS: "/admin/getAssignmentDetails"
    },
    BRANCH: {
        LIST: "/admin/getBranch",
        ADD: "/admin/addBranch",
        DELETE: "/admin/removeBranch"
    },
    ORDERS: {
        LIST: "/admin/getOrders"
    },
    FUND: {
        LIST: "/admin/getFundCollection"
    }
}