import React, { useState } from "react";
import OTPInput from "../components/common/Form/OTPInput";
import Button from "../components/common/Button/Button";
import { Link, useNavigate } from "react-router-dom";
import { GoArrowLeft } from "react-icons/go";
import { imageUrl } from "../config/url";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import { verifyOTP } from "../redux/actions/auth.action";
import ButtonLoading from "../components/custom/ButtonLoading";

const VerifyOtp = () => {
  const [otp, setOtp] = useState(["", "", "", ""]);
  const navigate = useNavigate();

  const handleChange = (index, value) => {
    const newOtp = [...otp];
    newOtp[index] = value;
    setOtp(newOtp);
  };
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state.auth)
  return (
    <>
      <div className="grid sm:grid-cols-2 grid-cols-1 justify-center sm:h-screen h-full items-center w-full bg-mainBG">
        <div className="max-md:px-5 flex flex-col items-center justify-center sm:py-0 py-10 space-y-10 md:space-y-36">
          <div>
            <img
              // className="w-[344px] h-[436px] "
              src={`${imageUrl}images/Authentication.png`}
              alt="hand-logo"
            />
          </div>
          {/* <div className="lg:px-0 md:px-5">
            <h1 className="text-3xl xs:text-[34px] max-md:leading-[44px] md:text-4xl font-bold text-[#fff] text-center">
              lorem ipsum dummy text
            </h1>
            <p className="text-lg md:text-2xl mt-2 text-[#fff] font-normal text-center  lg:w-[492px]">
              Lorem Ipsum is simply dummy text of the printing and typesetting
              industry.
            </p>
          </div> */}
        </div>
        <div className="w-full max-md:px-5 flex flex-col items-center justify-center bg-white h-screen ">
          <div className="flex justify-center mb-6">
            <img src={`${imageUrl}images/AuthenticationIcon.png`} alt="lock" />
          </div>
          <form className="w-full">
            <h2 className="text-mainBG text-3xl md:text-1xl  font-semibold text-center">
              Authentication
            </h2>
            <div className="flex justify-center">
              <p className="text-lg mt-4 font-medium text-textHeading text-center md:w-[336px] w-full">
                Enter the verification code we just sent to your email address.
              </p>
            </div>
            <div className="mt-8 flex  justify-center items-center   w-full">
              <div className="flex flex-col items-center space-y-8 md:w-[360px] w-full">
                <div className="flex gap-x-4">
                  {otp.map((value, index) => (
                    <OTPInput
                      key={index}
                      id={`otp-${index}`}
                      name={`otp-${index}`}
                      prevElement={index > 0 ? `otp-${index - 1}` : null}
                      nextElement={
                        index < otp.length - 1 ? `otp-${index + 1}` : null
                      }
                      onChange={(e) => handleChange(index, e.target.value)}
                    />
                  ))}
                </div>

                <Button
                  text={loading ? <ButtonLoading /> : "Verify Code"}
                  className="py-3 rounded-[10px] flex justify-center font-medium text-xl w-full"
                  type={true}
                  onClick={() => {
                    if (otp.join("").length == 4) {
                      dispatch(verifyOTP({
                        otp: otp.join("")
                      })).unwrap()
                      .then((res) => {
                        navigate('/reset_password', {
                          state: {
                            verified: true
                          }
                        })
                      }).catch((error)=>{
                        navigate('/reset_password', {
                          state: {
                            verified: true
                          }
                        })
                      })
                    } else {
                      toast.error("OPT is required")
                    }
                  }}
                ></Button>
              </div>
            </div>
            <div className="flex justify-center mt-20 ">
              <Link
                className="flex items-center gap-3 text-[#808080] text-lg font-normal"
                to="/"
              >
                <GoArrowLeft /> <span>Back to Sign In</span>
              </Link>
            </div>
          </form>
        </div>
      </div>
    </>
  );
};

export default VerifyOtp;
