import React, { useEffect, useMemo, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import RadioButton from "../Form/RadioButton";
import InputWithLabel from "../Form/InputWithLabel";
import DateTimePicker from "../Form/DateTimePicker";
import SelectBox from "../Form/SelectBox";
import Button from "../Button/Button";
import { imageUrl } from "../../../config/url";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../../../redux/actions/location.action";

import Select from "../../custom/Select";
import FormProvider from "../Form/FormProvider";
import { useForm } from "react-hook-form";
import { convertDateString, convertToUTC } from "../../../config/helper";
import { addAssignment, getAssignment } from "../../../redux/actions/assignment.action";
import InputField from "../../custom/InputField";
import { yupResolver } from "@hookform/resolvers/yup";
import { AddAssignmentSchema } from "../../../config/schema";


const plans = [
  {
    type: "cargo",
    src: `${imageUrl}icons/Tempo.png`,
    label: 'Cargo'
  },
  {
    type: "air_cargo",
    src: `${imageUrl}icons/Airplane.png`,
    label: 'Air Cargo'
  },
];

const cities = ["New York", "Tokyo", "Toronto", "Ottawa", "Edmonton"];

const AddAssignment = ({ data, setData, activeTab, limit,activeButton }) => {
  const [selectedPlan, setSelectedPlan] = useState("cargo");
  const [branch, setBranch] = useState([])
  const dispatch = useDispatch();
  const defaultValues = useMemo(() => ({
    name: "",
    branch: ""
  }), [])
  const { branchData } = useSelector((state) => state.location)

  const { loading } = useSelector((state) => state.assignment)
  useEffect(() => {
    dispatch(getBranch({
      user_id: localStorage.getItem('user_id')
    }))
  }, [])
  useEffect(() => {
    if (branchData.length > 0) {
      setBranch(branchData.map((item) => ({
        value: item._id,
        label: item.name
      })))
    }
  }, [branchData])
  const [selectedDate, setSelectedDate] = useState(new Date());

  const handleDateChange = (date) => {
    setSelectedDate(date);
  };
  const onClose = () => {
    setData({
      open: false,
      data: null
    })
    reset(defaultValues)
    setSelectedPlan('cargo')
  }
  const methods = useForm({
    resolver: yupResolver(AddAssignmentSchema),
    defaultValues
  })
  const { handleSubmit, reset } = methods;
  const onSubmit = (values) => {
   
    dispatch(addAssignment({
      user_id: localStorage.getItem('user_id'),
      branch: values.branch,
      datetime:convertToUTC(convertDateString(selectedDate)),
      shipping_type: selectedPlan,
      name: values.name
    })).unwrap().then(() => {
      onClose();
      dispatch(getAssignment({
        user_id: localStorage.getItem('user_id'),
        shipping_type: activeTab,
        page: 1,
        limit: limit.value,
        search: "",
        status:activeButton
      }))


    }).catch(() => {

    })

  }
  return (
    <>
      <Dialog open={data.open} onOpenChange={onClose}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">
                Create Assignment
              </h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={onClose}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <FormProvider onSubmit={handleSubmit(onSubmit)} methods={methods} >
              <div className="h-[500px]  overflow-y-auto noScrollbar">
                <div className="px-4 space-y-4">
                  <p className="text-sm font-bold text-[#0F172A]">Shipping Type</p>
                  <div className="sm:grid sm:grid-cols-2 max-sm:flex max-sm:flex-col  gap-4 mt-3 mx-auto max-w-5xl ">
                    {plans.map((plan) => (
                      <div
                        key={plan.type}
                        className={`relative bg-white  items-center rounded-lg px-5 py-3 cursor-pointer ${selectedPlan === plan.type
                          ? "border-2 border-[#383F90]"
                          : "border-2 border-[#E2E8F0]"
                          }`}
                      >
                        <RadioButton
                          label={
                            <div className="flex w-full justify-between items-center">
                              <div className="flex gap-3 items-center">
                                <div className="flex items-center justify-center w-[50px] h-[50px] rounded-xl bg-[#F7F7FA]">
                                  <img src={plan.src} alt="not found" />
                                </div>
                                <span className="plan-type text-base font-normal">
                                  {plan.label}
                                </span>
                              </div>
                            </div>
                          }
                          checked={selectedPlan === plan.type}
                          onChange={() => setSelectedPlan(plan.type)}
                          className="w-full"
                        />
                      </div>
                    ))}
                  </div>
                </div>
                <div className="space-y-5 px-4 mt-5">
                  <InputField
                    label="Assignment Name"
                    name="name"
                    placeholder="Assignment Name"
                    type={"text"}
                    className="font-medium"
                  />
                  <DateTimePicker
                    selectedDate={selectedDate}
                    handleDateChange={handleDateChange}
                    label={"Date & Time"}
                    className="font-semibold"
                  />
                  <Select placeholder={"Select Branch"} label={"Branch Location"} name="branch" options={branch} />
                </div>
                <div className="mt-8 px-4 pb-3">
                  <Button
                    text="Create"
                    type={true}
                    buttonType="submit"
                    className="py-3 rounded-xl text-base font-semibold w-full"
                  />
                </div>
              </div>
            </FormProvider>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AddAssignment;
