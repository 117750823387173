import React from "react";
import { cn } from "../../lib/utlis";

const Button = ({ text, className, type,buttonType="button",...other }) => {
  return (
    <button
      type={buttonType}
      className={cn(
        `${
          !type ? "bg-[#E7E6E9] text-[#808080]" : "bg-mainBG disabled:bg-mainBG/50 text-white"
        }`,
        className
      )}
      
      {...other}
    >
      {text}
    </button>
  );
};

export default Button;
