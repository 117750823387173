import { createSlice } from "@reduxjs/toolkit";
import { getBranch } from "../actions/location.action";

const branchSlice = createSlice({
    name: "branch",
    initialState: {
        loading: false,
        branchData: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getBranch.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getBranch.fulfilled, (state, action) => {
                state.branchData = action.payload.data.branch
                state.loading = false
            })
            .addCase(getBranch.rejected, (state, action) => {
                state.loading = false
                state.branchData = []
            })



    }
})

export default branchSlice.reducer;