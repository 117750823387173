import React from "react";

const StarIcon = ({ index, rating }) => {
  const isFilled = index < rating;
  return (
    <svg
      width="27"
      height="28"
      viewBox="0 0 27 28"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={`stroke-[#FFD600] stroke-[3px] ${
        isFilled ? "fill-[#FFD600]" : ""
      }`}
    >
      <path
        d="M15.4898 3.73301L17.5129 7.99142C17.7888 8.58421 18.5244 9.15281 19.1451 9.26169L22.8119 9.90287C25.1568 10.3142 25.7086 12.1047 24.0189 13.8709L21.1682 16.8712C20.6854 17.3793 20.421 18.3592 20.5705 19.0609L21.3866 22.7749C22.0303 25.7147 20.5475 26.8518 18.0761 25.3154L14.6392 23.1741C14.0185 22.787 12.9955 22.787 12.3633 23.1741L8.92637 25.3154C6.46652 26.8518 4.97221 25.7026 5.61591 22.7749L6.43203 19.0609C6.58146 18.3592 6.31708 17.3793 5.83431 16.8712L2.98363 13.8709C1.30541 12.1047 1.84566 10.3142 4.19057 9.90287L7.85737 9.26169C8.46659 9.15281 9.20224 8.58421 9.47812 7.99142L11.5012 3.73301C12.6047 1.42233 14.3978 1.42233 15.4898 3.73301Z"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};

export default StarIcon;
