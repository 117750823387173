import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import ProductFrom from "../Form/ProductFrom";
import ProductSummry from "../Form/ProductSummry";
import { useDispatch, useSelector } from "react-redux";
import { getReview } from "../../../redux/actions/review.action";

import { ScrollArea, ScrollBar } from "../../custom/scroll-area";

const ReviewModal = ({ data, setData }) => {
  const [activeButton, setActiveButton] = useState(0);
  const { reviewData, loading } = useSelector((state) => state.review);
  
  const onClose = () => {
    setData({
      open: false,
      data: null
    })
  }

  const id = data.data?._id;
  useEffect(() => {
    if (id) {
      dispatch(getReview({
        user_id: localStorage.getItem('user_id'),
        order_id: id
      }))
    }
  }, [id])
  const dispatch = useDispatch();

  return (
    <>
      <Dialog open={data.open} onOpenChange={onClose}>
        <DialogContent className="overflow-x-hidden">
          <DialogHeader asChild>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">Review</h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={onClose}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>

          <ScrollArea className="overflow-x-hidden pb-4 w-full">

            <div className="flex  overflow-x-auto relative  gap-3 px-5 pt-2">
              {
                reviewData?.products?.length > 0 ? reviewData.products.map((item, index) => (
                  <Button
                    className={`py-1 rounded-full flex items-center flex-nowrap text-nowrap font-medium text-[17px] sm:text-lg  px-3  ${activeButton === index ? "bg-blue-500 text-white" : ""
                      }`}
                    text={`Product ${index + 1}`}
                    type={activeButton === index}
                    onClick={() => setActiveButton(index)}
                  />
                )) : <></>
              }

              <Button
                className={`py-1 rounded-full font-medium text-[17px] sm:text-lg  px-3  ${activeButton === "Summary" ? "bg-blue-500 text-white" : ""
                  }`}
                text="Summary"
                type={activeButton === "Summary"}
                onClick={() => setActiveButton("Summary")}
              />
            </div>
            <ScrollBar className="h-2" orientation='horizontal' />
          </ScrollArea>

          <div>
            <div className="px-6 pt-4 h-[500px] w-full space-y-5 overflow-y-auto noScrollbar">
              {reviewData?.products?.length > 0 && !loading && activeButton != "Summary" && <ProductFrom data={reviewData}  row={data.data} active={activeButton} />}

              {reviewData?.products?.length > 0 && activeButton == "Summary" && <ProductSummry  onClose={onClose} data={reviewData} row={data.data} active={activeButton} />}

            </div>
          </div>

        </DialogContent>
      </Dialog >
    </>
  );
};

export default ReviewModal;
