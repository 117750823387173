import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const DateTimePicker = ({ selectedDate, handleDateChange, label,className="" }) => {
  const [startDate, setStartDate] = useState(selectedDate || new Date());

  const handleChange = (date) => {
    setStartDate(date);
    handleDateChange(date);
  };

  return (
    <div className="flex flex-col space-y-4">
       {label && <label className="text-sm font-semibold text-[#0F172A]">{label}</label>}
      <DatePicker
        selected={startDate}
        onChange={handleChange}
        showTimeSelect
        dateFormat="dd-MM-yyyy hh:mm aa"
        className={`py-3 px-3 border border-borderColor rounded-xl w-full ${className}`}
      />
    </div>
  );
};

export default DateTimePicker;
