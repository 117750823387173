import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api";
import { endPoints } from "../../config";
import { toast } from "react-toastify";
import { values } from "lodash";

const getAmountRequest = createAsyncThunk("GET_AMOUNT_REQUREST_DATA", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.GET_AMOUNT_REQUEST, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})

const settleFundAmoutRequest = createAsyncThunk("SETTLE_AMOUNT_REQUEST", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.SETTLE_AMOUNT_REQUEST, values);
        toast.success(data?.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg)
        return rejectWithValue(error)
    }
})
const updateRequestAmount = createAsyncThunk("UPDATE_REQUEST_AMOUNT", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.UPDATE_AMOUNT_REQUEST, values);
        toast.success(data?.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error)
    }
})

const getAmountRequestByBranchId = createAsyncThunk("ADMIN_AMOUNT_REQUEST", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ADMIN_AMOUNT_REQUEST, values);
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg)
        return rejectWithValue(error);
    }

})

export { getAmountRequest, updateRequestAmount, settleFundAmoutRequest, getAmountRequestByBranchId }