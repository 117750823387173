import { createSlice } from "@reduxjs/toolkit";
import { getAmountRequest, getAmountRequestByBranchId, settleFundAmoutRequest, updateRequestAmount } from "../actions/amount.action";

const amountSlice = createSlice({
    name: "amount",
    initialState: {
        loading: false,
        amountData: [],
        total: 0
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(getAmountRequest.pending, (state, action) => {
            state.loading = true
        }).addCase(getAmountRequest.fulfilled, (state, action) => {
            state.amountData = action.payload.data?.amountRequest
            state.total = action.payload.data?.totalRecords
            state.loading = false
        }).addCase(getAmountRequest.rejected, (state, action) => {
            state.loading = false
            state.amountData = [];
        })
        builder.addCase(updateRequestAmount.pending, (state, action) => {
            state.loading = true
        }).addCase(updateRequestAmount.fulfilled, (state, action) => {
            state.loading = false;
        }).addCase(updateRequestAmount.rejected, (state, action) => {
            state.loading = false;
        })
        builder.addCase(settleFundAmoutRequest.pending, (state, action) => {
            state.loading = true;
        }).addCase(settleFundAmoutRequest.fulfilled, (state, action) => {
            state.loading = false
        }).addCase(settleFundAmoutRequest.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getAmountRequestByBranchId.pending, (state, action) => {
            state.loading = true;
        }).addCase(getAmountRequestByBranchId.fulfilled, (state, action) => {
            state.amountData = action.payload.data?.amountRequest
            state.total = action.payload.data?.totalRecords
            state.loading = false
        }).addCase(getAmountRequestByBranchId.rejected, (state, action) => {
            state.loading = false
        })
    }

})

export default amountSlice.reducer