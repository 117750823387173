import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../config/api";
import { endPoints } from "../../config";

const getOrders = createAsyncThunk('GET_ORDERS', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.ORDERS.LIST, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})


export { getOrders } 