import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import DataTable from "../components/common/Tabel/DataTabel";
import useColumns from "../components/hooks/useColumns";
import AddFundModal from "../components/common/Modal/AddFundModal";
import { useDispatch, useSelector } from "react-redux";
import { getDriver } from "../redux/actions/auth.action";
import { getUserData } from "../redux/actions/user.action";
import { options } from "../data/data";
import GenericTable from "../components/custom/GenericTable";
import debounce from "lodash/debounce";
import { SearchDataChange } from "../components/hooks/SearchDataContext";

const AddFund = () => {
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const { loading, users, total } = useSelector((state) => state.user);
  const [addFundModal, setAddFundModal] = useState({
    open: false,
    data: null,
  });
  const handleView = (data) => {
    setAddFundModal({
      open: true,
      data: data,
    });
    // setOpenmodal(true);
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const { addFundsColumns } = useColumns({ handleView });
  return (
    <>
      <Layout role="branchAdmin" title="Add Funds">
        {/* <DataTable data={driverData} columns={addFundsColumns} /> */}
        <GenericTable
          limitVal={limitVal}
          getData={getUserData}
          setLimitVal={setLimitVal}
          total={total}
          loading={loading}
          search={search}
          data={users}
          columns={addFundsColumns}
          message=" Fund Not Found"
        />
        <AddFundModal
          openModal={addFundModal}
          closeModal={() => {
            setAddFundModal({
              open: false,
              data: null,
            });
          }}
        />
      </Layout>
    </>
  );
};

export default AddFund;
