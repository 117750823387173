import React, { useState } from "react";
import Button from "../components/common/Button/Button";
import Layout from "../components/common/Layouts/Layout";
import DataTable from "../components/common/Tabel/DataTabel";
import useColumns from "../components/hooks/useColumns";
import ViewOrdersModal from "../components/common/Modal/ViewOrdersModal";
import { useSelector } from "react-redux";
import { options } from "../data/data";
import GenericTable from "../components/custom/GenericTable";
import { getAmountRequestByBranchId } from "../redux/actions/amount.action";
import { useParams } from "react-router-dom";

const BranchRecord = () => {
  const { id } = useParams();
  const [activeButton, setActiveButton] = useState("accepted");
  const [limitVal, setLimitVal] = useState(options[1]);
  const [statusData, setStatusData] = useState({
    status: activeButton,
    branch_id: id,
  });
  const { loading, total, amountData } = useSelector((state) => state.amount);

  const { FundCollectionRequest } = useColumns({});

  const renderContent = () => {
    switch (activeButton) {
      case "accepted":
        return (
          <GenericTable
            columns={FundCollectionRequest}
            data={amountData}
            getData={getAmountRequestByBranchId}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            // search={search}
            extra={statusData}
            message="Accepted Requests Not Found"
          />
        );
      case "rejected":
        return (
          <GenericTable
            columns={FundCollectionRequest}
            data={amountData}
            getData={getAmountRequestByBranchId}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            // search={search}
            extra={statusData}
            message="Rejected Requests Not Found"
          />
        );
      case "pending":
        return (
          <GenericTable
            columns={FundCollectionRequest}
            data={amountData}
            getData={getAmountRequestByBranchId}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            // search={search}
            extra={statusData}
            message="Pending Requests Not Found"
          />
        );
      default:
        return (
          <GenericTable
            columns={FundCollectionRequest}
            data={amountData}
            getData={getAmountRequestByBranchId}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={statusData}
            // search={search}
            extra={statusData}
            message="Accepted Orders Not Found"
          />
        );
    }
  };

  return (
    <>
      <Layout title="Request Records">
        <div className="flex justify-end max-sm:flex-wrap gap-5 mt-3 px-4">
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${
              activeButton === "accepted" ? "bg-blue-500 text-white" : ""
            }`}
            text="Accepted"
            type={activeButton === "accepted"}
            onClick={() => {
              setActiveButton("accepted");
              setStatusData({ status: "accepted", branch_id: id });
            }}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[170px] ${
              activeButton === "rejected" ? "bg-blue-500 text-white" : ""
            }`}
            text="Rejected"
            type={activeButton === "rejected"}
            onClick={() => {
              setActiveButton("rejected");
              setStatusData({ status: "rejected", branch_id: id });
            }}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[130px] ${
              activeButton === "pending" ? "bg-blue-500 text-white" : ""
            }`}
            text="Pending"
            type={activeButton === "pending"}
            onClick={() => {
              setActiveButton("pending");
              setStatusData({ status: "pending", branch_id: id });
            }}
          />
        </div>
        {renderContent()}
      </Layout>
    </>
  );
};

export default BranchRecord;
