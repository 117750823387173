import { createSlice } from "@reduxjs/toolkit";
import { addFund, forgot_password, getDriver, login, resetPassword, verifyOTP } from "../actions/auth.action";


const authSlice = createSlice({
    name: "auth",
    initialState: {
        loading: false,
        drivers: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder.addCase(login.pending, (state, action) => {
            state.loading = true
        })
            .addCase(login.fulfilled, (state, action) => {
                localStorage.setItem("admin_id", action.payload.data.admin_id)
                localStorage.setItem('name', action.payload.data.name)
                localStorage.setItem('user_id', action.payload.data._id)
                localStorage.setItem('email', action.payload.data.email)
                localStorage.setItem('token', action.payload.data.token)
                localStorage.setItem('role', action.payload.data.user_type);
                localStorage.setItem("city", action.payload.data.city)
                state.loading = false;
            })
            .addCase(login.rejected, (state, action) => {
                state.loading = false
            })
        builder.addCase(forgot_password.pending, (state, action) => {
            state.loading = true;
        })
            .addCase(forgot_password.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(forgot_password.rejected, (state, action) => {
                state.loading = false
            })
        builder.addCase(verifyOTP.pending, (state, action) => {
            state.loading = true;
        })
            .addCase(verifyOTP.fulfilled, (state, action) => {
                state.loading = false;
            })
            .addCase(verifyOTP.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(resetPassword.pending, (state, action) => {
                state.loading = true;
            })
            .addCase(resetPassword.fulfilled, (state, action) => {
                state.loading = false
                localStorage.removeItem('forgot_email')
            })
            .addCase(resetPassword.rejected, (state, action) => {
                state.loading = false
            })
        builder.addCase(getDriver.pending, (state, action) => {
            state.loading = true
        }).addCase(getDriver.fulfilled, (state, action) => {
            state.loading = false;
            state.drivers = action.payload.data

        }).addCase(getDriver.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(addFund.pending, (state, action) => {
            state.loading = true;
        }).addCase(addFund.fulfilled, (state, action) => {
            state.loading = false;
        }).addCase(addFund.rejected, (state, action) => {
            state.loading = false
        })
    }
})

export default authSlice.reducer;