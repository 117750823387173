import React from "react";
import Layout from "../components/common/Layouts/Layout";
import Chat from "../components/common/Chat";

const AdminChat = () => {
  return (
    <>
      <Layout title="Chat">
        <Chat />
      </Layout>
    </>
  );
};

export default AdminChat;
