import React, { useCallback, useEffect, useState } from "react";
import Button from "../components/common/Button/Button";
import Layout from "../components/common/Layouts/Layout";
import DataTable from "../components/common/Tabel/DataTabel";
import useColumns from "../components/hooks/useColumns";
import AddBranch from "../components/common/Modal/AddBranch";
import DeleteBranch from "../components/common/Modal/DeleteBranch";
import { imageUrl } from "../config/url";
import { useDispatch, useSelector } from "react-redux";
import { getBranch } from "../redux/actions/branch.action";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";
import { options } from "../data/data";
import GenericTable from "../components/custom/GenericTable";

const Branch = () => {
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const [deleteModal, setDeleteModal] = useState({
    open: false,
    data: null,
  });
  const [addModal, setAddModal] = useState({
    open: false,
    data: null,
  });

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleDelete = (row) => {
    setDeleteModal({
      open: true,
      data: row,
    });
  };
  const { loading, branchData, total } = useSelector((state) => state.branch);

  // useEffect(() => {
  //   dispatch(
  //     getBranch({
  //       user_id: localStorage.getItem("user_id"),
  //     })
  //   );
  // }, []);
  const { BranchColumns } = useColumns({ handleDelete });
  return (
    <>
      <Layout title="Branch">
        <div className="flex justify-end px-4 mt-2">
          <Button
            text="Add Branch"
            type={true}
            className="py-3 rounded-lg w-[160px] font-semibold"
            onClick={() => {
              setAddModal({
                data: null,
                open: true,
              });
            }}
          />
        </div>
        <div className="mt-3">
          {/* <DataTable columns={BranchColumns} data={branchData} /> */}
          <GenericTable
            limitVal={limitVal}
            getData={getBranch}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            search={search}
            data={branchData}
            columns={BranchColumns}
            height="h-[70vh]"
            message="Branch Not Found"
          />
        </div>
        <AddBranch data={addModal} setData={setAddModal} />
        <DeleteBranch data={deleteModal} setData={setDeleteModal} />
      </Layout>
    </>
  );
};

export default Branch;
