import React, { useEffect, useRef, useState } from "react";
import SidebarItem from "../SidebarItem";
import { RxCrosshair1, RxDashboard } from "react-icons/rx";
import { IoCloseCircle } from "react-icons/io5";
import SearchBar from "../Form/SearchBar";
import { IoNotificationsOutline } from "react-icons/io5";
import { IoIosArrowDown } from "react-icons/io";
import HeaderSearchBar from "../Form/HeaderSearchBar";
import { imageUrl } from "../../../config/url";
import { useLocation, useNavigate } from "react-router-dom";
import { FaMoneyBill } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import { getAdminNotificationList } from "../../../redux/actions/dashboard.action";
import moment from "moment";
import { convertToLocal } from "../../../config/helper";

const Layout = ({ title, children, role }) => {
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [notificationOpen, setNotificationOpen] = useState(false);
  const [limit, setLimit] = useState(20);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const notificationRef = useRef(null);
  const { notificationData, loading } = useSelector((state) => state.dashboard);
  const { pathname } = useLocation();

  const toggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
  };
  const toggleNotificationPanel = () => setNotificationOpen(!notificationOpen);

  const handleLogout = () => {
    localStorage.removeItem("user_id");
    localStorage.removeItem("role");
    localStorage.removeItem("name");
    localStorage.removeItem("token");
    localStorage.removeItem("email");
    window.location = imageUrl;
  };
  const handleScroll = () => {
    const scrollElement = notificationRef.current;
    if (scrollElement) {
      const { scrollTop, scrollHeight, clientHeight } = scrollElement;
      if (scrollTop + clientHeight >= scrollHeight - 5) {
        limit < notificationData?.totalRecords &&
          setLimit((prevLimit) => prevLimit + 5);
      }
    }
  };

  useEffect(() => {
    const scrollElement = notificationRef.current;
    if (scrollElement) {
      scrollElement.addEventListener("scroll", handleScroll);
    }
    return () => {
      if (scrollElement) {
        scrollElement.removeEventListener("scroll", handleScroll);
      }
    };
  }, [handleScroll]);

  useEffect(() => {
    dispatch(
      getAdminNotificationList({
        user_id: localStorage.getItem("user_id"),
        page: 1,
        limit: limit,
        read: false,
      })
    );
  }, []);

  const handleGetNotificationData = () => {
    dispatch(
      getAdminNotificationList({
        user_id: localStorage.getItem("user_id"),
        page: 1,
        limit: limit,
        read: true,
      })
    );
  };

  useEffect(() => {
    if (notificationOpen === true) {
      handleGetNotificationData();
    }
  }, [limit]);

  return (
    <div className="flex h-screen bg-gray-100 overflow-hidden">
      {/* Sidebar */}
      <div
        className={`fixed inset-y-0 left-0 z-30 w-64 bg-mainBG shadow-lg transform transition-transform duration-300 md:relative md:translate-x-0 ${sidebarOpen ? "translate-x-0" : "-translate-x-full"
          }`}
      >
        <div className="flex flex-col h-full">
          <div className="p-4 flex-grow">
            <div className="py-3 border-b border-white border-opacity-20">
              <h2 className="text-[28px] font-bold text-white leading-10">
                PIKA
              </h2>
            </div>
            <nav className="mt-4 flex-grow">
              {role !== "branchAdmin" ? (
                <>
                  <SidebarItem
                    path="/dashboard"
                    label="Dashboard"
                    icon={<RxDashboard className="text-xl font-medium" />}
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/DashboardActive.svg`}
                        alt=""
                      />
                    }
                  />
                  <SidebarItem
                    path="/assignment"
                    label="Assignments"
                    icon={
                      <img
                        src={`${imageUrl}icons/assignments.svg`}
                        alt="assignments"
                      />
                    }
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/AssignmentActive.svg`}
                        alt=""
                      />
                    }
                  />
                  <SidebarItem
                    path="/orders"
                    label="Orders"
                    icon={
                      <img src={`${imageUrl}icons/orders.svg`} alt="orders" />
                    }
                    activeIcon={
                      <img src={`${imageUrl}icons/OrderActive.svg`} alt="" />
                    }
                  />
                  <SidebarItem
                    path="/chat"
                    label="Chat"
                    icon={
                      <img src={`${imageUrl}icons/chatIcon.svg`} alt="chat" />
                    }
                    activeIcon={
                      <img src={`${imageUrl}icons/chatActive.svg`} alt="" />
                    }
                  />
                  <SidebarItem
                    path="/fund-collection"
                    label="Fund Collection"
                    icon={
                      <img
                        src={`${imageUrl}icons/fund.svg`}
                        alt="fund-collection"
                      />
                    }
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/FundColletionActive.svg`}
                        alt="fund-collection"
                      />
                    }
                  />
                  <SidebarItem
                    path="/branch"
                    label="Branch"
                    icon={
                      <img src={`${imageUrl}icons/shop.svg`} alt="branch" />
                    }
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/BranchActive.svg`}
                        alt="branch"
                      />
                    }
                  />
                </>
              ) : (
                <>
                  <SidebarItem
                    path="/add-fund"
                    label="Add Funds"
                    icon={
                      <img
                        src={`${imageUrl}icons/fund.svg`}
                        alt="fund-collection"
                      />
                    }
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/FundColletionActive.svg`}
                        alt="fund-collection"
                      />
                    }
                  />
                  <SidebarItem
                    path="/branch-orders"
                    label="Orders"
                    icon={
                      <img src={`${imageUrl}icons/orders.svg`} alt="orders" />
                    }
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/OrderActive.svg`}
                        alt="orders"
                      />
                    }
                  />
                  <SidebarItem
                    path="/drivers"
                    label="Driver"
                    icon={
                      <img
                        src={`${imageUrl}icons/ProfileUser.svg`}
                        alt="drivers"
                      />
                    }
                    activeIcon={
                      <img
                        src={`${imageUrl}icons/driverActive.svg`}
                        alt="drivers"
                      />
                    }
                  />
                  <SidebarItem
                    path="/branch-chat"
                    label="Chat"
                    icon={
                      <img src={`${imageUrl}icons/chatIcon.svg`} alt="chat" />
                    }
                    activeIcon={
                      <img src={`${imageUrl}icons/chatActive.svg`} alt="chat" />
                    }
                  />
                  <SidebarItem
                    path="/close-amount-request"
                    label="Amount Request"
                    icon={<FaMoneyBill className="text-white text-lg" />}
                    activeIcon={<FaMoneyBill className="text-lg" />}
                  />
                </>
              )}
            </nav>
          </div>
          {/* Logout Button */}
          <div className="px-4   ">
            <div className="border-t py-6 border-white border-opacity-20 flex gap-4 px-3">
              <img src={`${imageUrl}icons/Logout.svg`} alt="not found" />
              <button
                className="text-white text-base font-normal hover:bg-opacity-75 transition-all"
                onClick={handleLogout}
              >
                Logout
              </button>
            </div>
          </div>
        </div>
      </div>

      {/* Main Content Wrapper */}
      <div className="flex-1 flex flex-col overflow-hidden">
        {/* Header */}
        <div className="bg-white px-4 xs:px-8 py-5 flex justify-between items-center">
          <div className="flex items-center">
            <button
              className="text-gray-500 max-md:mr-2 focus:outline-none md:hidden"
              onClick={toggleSidebar}
            >
              ☰
            </button>
            <h1 className="lg:text-2xl md:text-xl text-lg font-semibold text-[#0F172A] md:ml-5 ml-1 text-start md:ml-0">
              {title}
            </h1>
          </div>

          <div className="flex items-center gap-x-4 xs:gap-10">
            {
              pathname.startsWith("/chat") || pathname.startsWith("/branch-chat") ? "" : <HeaderSearchBar type="text" placeholder="Search anything..." />
            }

            <div
              className="relative cursor-pointer"
              onClick={() => {
                handleGetNotificationData();
                toggleNotificationPanel();
              }} // Open/close notification panel
            >
              <IoNotificationsOutline className="text-[#64748B] text-2xl font-semibold" />
              <div className="w-3.5 h-3.5 rounded-full bg-[#ED4F9D] absolute z-10 -top-0.5 left-3 flex justify-center items-center">
                <p className="text-[10px] text-white">
                  {notificationData?.unread}
                </p>
              </div>
            </div>
            <div className="flex gap-x-3 xs:gap-5 items-center bg-slate-400 rounded-full">
              <div className="w-10 h-10 rounded-full bg-slate-400">
                <img src={`${imageUrl}images/Profile.png`} alt="profile" />
              </div>
            </div>
          </div>
        </div>

        {/* Notification Panel */}
        <div
          className={`fixed inset-y-0 right-0 z-40 w-[22%] bg-white  shadow-lg transform transition-transform duration-300 ${notificationOpen ? "translate-x-0" : "translate-x-full"
            }`}
        >
          <div className="flex justify-between items-center p-4 border-b">
            <h2 className="text-xl font-semibold">Notifications</h2>
            <button
              className="text-xl"
              onClick={() => {
                toggleNotificationPanel();
                handleGetNotificationData();
              }}
            >
              <IoCloseCircle className="size-6 text-[#383F90]" />
            </button>
          </div>
          <div className="p-4 h-dvh overflow-y-scroll" ref={notificationRef}>
            {notificationData?.notifications?.length > 0 ? (
              <>
                {notificationData?.notifications?.map((val) => {
                  return (
                    <div className="flex justify-between items-center border-b border-[#94A3B8] py-3 w-full">
                      <div className="flex flex-col w-[100%]">
                        <h6 className="text-base font-semibold text-start">
                          {val?.title}
                        </h6>
                        <p className="text-start text-sm font-normal ">
                          {val?.message}
                        </p>
                        <p className="text-sm font-normal text-start text-[#94A3B8] mt-1">
                          {moment(convertToLocal(val?.createdAt)).format(
                            "DD MMM YYYY hh:mm A"
                          )}
                        </p>
                      </div>
                    </div>
                  );
                })}
              </>
            ) : (
              <div className="flex items-center justify-center h-full">
                <p>No data found</p>
              </div>
            )}
          </div>
        </div>

        {/* Main Content */}
        <div className="flex-1 overflow-auto h-[92vh] bg-[#f9f9f9]">
          <main
            className="flex-1 p-4 bg-[#f9f9f9] h-full overflow-scroll"
            onClick={() => setSidebarOpen(false)}
          >
            {children}
          </main>
        </div>
      </div>
    </div>
  );
};

export default Layout;
