import { createSlice } from "@reduxjs/toolkit";
import { acceptAssignmentData, getAssignmentData, getAssignmentOrder, getOrderDataByStatus, getProductDetails, getProductFeedback, addAssignment, getAssignment, getAssignmentDetails, getPendingAssignment } from "../actions/assignment.action";

const assignmentSlice = createSlice({
    name: "assignment",
    initialState: {
        loading: false,
        total: 0,
        assignment: [],
        orders: [],
        assignmentData: [],
        pendingAssignment: [],
        details: {},
        feedback: {},
        product: {},
    },
    reducers: {
        removeAssignmentData: (state, action) => {
            state.details = {}
        },
        removeAssignment: (state, action) => {
            state.assignmentData = []
        },
        removePendingAssignment: (state, action) => {
            state.pendingAssignment = []
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getAssignmentData.pending, (state, action) => {
            state.loading = true;
        }).addCase(getAssignmentData.fulfilled, (state, action) => {
            state.assignment = action.payload.data.assignments
            state.total = action.payload.data.totalRecords
            state.loading = false;
        }).addCase(getAssignmentData.rejected, (state, action) => {
            state.loading = false;
        })
        builder.addCase(getAssignmentOrder.pending, (state, action) => {
            state.loading = true;
        }).addCase(getAssignmentOrder.fulfilled, (state, action) => {
            state.orders = action.payload.data.orders
            state.total = action.payload.data.totalRecords
            state.loading = false;
        }).addCase(getAssignmentOrder.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(acceptAssignmentData.pending, (state, action) => {
            state.loading = true;
        }).addCase(acceptAssignmentData.fulfilled, (state, action) => {
            state.loading = false;
        }).addCase(acceptAssignmentData.rejected, (state, action) => {
            state.loading = false;

        })
        builder.addCase(getOrderDataByStatus.pending, (state, action) => {
            state.loading = true
        }).addCase(getOrderDataByStatus.fulfilled, (state, action) => {
            state.orders = action.payload.data.orders
            state.total = action.payload.data.totalRecords
            state.loading = false;
        }).addCase(getOrderDataByStatus.rejected, (state, action) => {
            state.loading = false;
        })
        builder.addCase(getProductFeedback.pending, (state, action) => {
            state.loading = true;
        }).addCase(getProductFeedback.fulfilled, (state, action) => {
            state.feedback = action.payload.data
            state.loading = false
        }).addCase(getProductFeedback.rejected, (state, action) => {
            state.loading = false
        })
        builder.addCase(getProductDetails.pending, (state, action) => {
            state.loading = true;
        }).addCase(getProductDetails.fulfilled, (state, action) => {
            state.product = action.payload.data
            state.loading = false;
        }).addCase(getProductDetails.rejected, (state, action) => {
            state.loading = false
        })
        builder
            .addCase(getAssignment.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAssignment.fulfilled, (state, action) => {
                state.assignmentData = action.payload.data.assignment
                state.total = action.payload.data.totalRecords
                state.loading = false
            })
            .addCase(getAssignment.rejected, (state, action) => {
                state.loading = false
                state.assignmentData = []
                state.total = 0
            })
            .addCase(addAssignment.pending, (state, action) => {
                state.loading = true
            })
            .addCase(addAssignment.fulfilled, (state, action) => {
                state.loading = false
            })
            .addCase(addAssignment.rejected, (state, action) => {
                state.loading = false
            })
            .addCase(getAssignmentDetails.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getAssignmentDetails.fulfilled, (state, action) => {
                state.details = action.payload.data
                state.loading = false
            })
            .addCase(getAssignmentDetails.rejected, (state, action) => {
                state.loading = false
                state.assignmentData = []
                state.total = 0
            })
            .addCase(getPendingAssignment.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getPendingAssignment.fulfilled, (state, action) => {
                state.pendingAssignment = action.payload.data.assignment
                state.loading = false
            })
            .addCase(getPendingAssignment.rejected, (state, action) => {
                state.loading = false
                state.pendingAssignment = []
            })
    }
})
export default assignmentSlice.reducer

export const { removeAssignmentData, removeAssignment, removePendingAssignment } = assignmentSlice.actions
