import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import "./App.css";
import NewLogin from "./pages/Login";
import ForgetPassword from "./pages/ForgetPassword";
import VerifyOtp from "./pages/VerifyOtp";
import ResetPassword from "./pages/ResetPassword";
import Dashboard from "./pages/Dashboard";
import Assignment from "./pages/Assignment";
import AssignmentDetail from "./pages/AssignmentDetail";
import Orders from "./pages/Orders";
import FundCollection from "./pages/FundCollection";
import Branch from "./pages/Branch";
import Chat from "./pages/AdminChat";
import AddFund from "./pages/AddFund";
import BranchOrders from "./pages/BranchOrders";
import Driver from "./pages/Driver";
import BranchChat from "./pages/BranchChat";
import ProtectAdmin from "./routes/ProtectAdmin";
import ProtectBranch from "./routes/ProtectBranch";
import Products from "./pages/Products";
import CloseRequest from "./pages/CloseRequest";
import BranchRecord from "./pages/BranchRecord";
import './assets/scss/style.css';
import VerifyGuard from "./guard/VerifyGuard";
import ResetGuard from "./guard/ResetGuard";
import AuthGuard from "./guard/AuthGuard";
import { SearchProvider } from "./components/hooks/SearchDataContext";
function App() {
  return (
    <SearchProvider>
      <div className="App">
        <BrowserRouter basename="/">
          <Routes>
            <Route element={<AuthGuard />}>
              <Route index element={<NewLogin />} />
            </Route>
            <Route path="/forget_password" element={<ForgetPassword />} />
            <Route path="/verify_otp" element={<VerifyGuard />} >
              <Route index={true} element={<VerifyOtp />} />
            </Route>
            <Route path="/reset_password" element={<ResetGuard />}>
              <Route index={true} element={<ResetPassword />} />
            </Route>

            {/* superadmin routes start */}
            <Route element={<ProtectAdmin />}>
              <Route path="/dashboard" element={<Dashboard />} />
              <Route path="assignment" element={<Outlet />}>
                <Route index element={<Assignment />} />
                <Route path=":id" element={<AssignmentDetail />} />
              </Route>
              <Route path="/orders" element={<Orders />} />
              <Route path="/fund-collection" element={<Outlet />} >
                <Route index element={<FundCollection />} />
                <Route path=":id" element={<BranchRecord />} />
              </Route>

              <Route path="/branch" element={<Branch />} />
              <Route path="/chat" element={<Chat />} />
            </Route>
            {/* superadmin routes end */}
            {/* branch admin routes start */}

            <Route element={<ProtectBranch />}>
              <Route path="/add-fund" element={<AddFund />} />
              <Route path="/branch-orders" element={<Outlet />} >
                <Route index element={<BranchOrders />} />
                <Route path=":id" element={<Products />} />
              </Route>
              <Route path="/drivers" element={<Driver />} />
              <Route path="/branch-chat" element={<BranchChat />} />

              <Route path="/close-amount-request" element={<CloseRequest />} />
            </Route>
            {/* branch admin routes end */}
          </Routes>
        </BrowserRouter>
      </div>
    </SearchProvider>
  );
}

export default App;
