import React from "react";
import { Link, useLocation } from "react-router-dom";

const SidebarItem = ({ path, label, icon, activeIcon }) => {
  const { pathname } = useLocation();
  return (
    <Link to={path} className={`flex items-center w-full`}>
      <div
        className={`flex gap-3 w-full items-center px-3 my-2 py-3 text-base font-medium ${
          pathname?.includes(path)
            ? "bg-white rounded-xl  text-[#383F90] "
            : "text-white bg-mainBG"
        }`}
      >
        {pathname?.includes(path) ? (
          <div className="">{activeIcon}</div>
        ) : (
          <div className="">{icon}</div>
        )}

        <span className="p-0 m-0">{label}</span>
      </div>
    </Link>
  );
};

export default SidebarItem;
