import React, { useEffect, useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import { imageUrl } from "../../../config/url";
import { useDispatch, useSelector } from "react-redux";
import { getProductDetails } from "../../../redux/actions/assignment.action";

const ViewDeliveryModal = ({ openModal, closeModal }) => {
  const nationalCharge = 0;
  const dispatch = useDispatch();
  const [quantity, setQuntity] = useState();
  const [total, setTotal] = useState();
  const [serivceCharge, setServiceCharge] = useState();
  const { loading, product } = useSelector((state) => state.assignment);
  useEffect(() => {
    openModal?.open == true &&
      dispatch(
        getProductDetails({
          user_id: localStorage.getItem("user_id"),
          order_id: openModal?.data?._id,
        })
      );
  }, [openModal, dispatch]);
  useEffect(() => {
    setQuntity(
      product?.products
        ?.filter((item) => item.status == "accepted")
        ?.reduce((n, { quantity }) => n + quantity, 0)
    );
    setTotal(
      product?.products
        ?.filter((item) => item.status == "accepted")
        ?.reduce((n, { quantity, price }) => n + price * quantity, 0)
    );
    setServiceCharge(
      product?.products
        ?.filter((item) => item.status == "accepted")
        ?.reduce((n, { service_charge }) => n + service_charge, 0)
    );
  }, [product]);
  return (
    <>
      <Dialog open={openModal?.open} onOpenChange={closeModal}>
        <DialogContent className="max-w-[700px]">
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">
                Order Details
              </h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="  max-h-[522px] overflow-y-auto noScrollbar ">
              <div className="pb-4 px-4 flex justify-between gap-5 border-b border-borderColor w-full">
                <div className="">
                  <p className="font-semibold text-lg text-black">
                    {product?.order?.user?.name}
                  </p>
                  <p className="text-base font-semibold text-[#808080] capitalize">
                    {product?.order?.user?.city}
                  </p>
                </div>
                <div className="flex justify-center items-center w-full">
                  <button
                    className={`  border-0 rounded-lg text-sm font-medium capitalize px-4 py-2 ${
                      product?.order?.status == "pending" &&
                      "text-[#F6A723] bg-[#FFFBEB]"
                    } ${
                      product?.order?.status == "completed" &&
                      "text-green-400 bg-green-200"
                    } ${
                      product?.order?.status == "delivery" &&
                      "text-[#F6A723] bg-[#FFFBEB]"
                    }`}
                  >
                    {product?.order?.status}
                  </button>
                </div>
                <div>
                  <p className="font-semibold text-black text-wrap break-words text-right">
                    {product?.order?.address?.line_1},
                    {product?.order?.address?.line_2},
                    {product?.order?.address?.city},
                    {product?.order?.address?.country},
                    {product?.order?.address?.pincode}
                  </p>
                </div>
              </div>
              <div className="grid sm:grid-cols-3  grid-cols-1 gap-3 py-3 px-4">
                <div className="flex items-center gap-2">
                  <img
                    src={`${imageUrl}images/ProfileChat.png`}
                    alt="profile chat "
                  />
                  <div className="space-y-2">
                    <p className="text-base font-semibold text-[#000000] capitalize">
                      {product?.order?.driver?.name}
                    </p>
                    <p className="text-base font-normal text-textColor">Name</p>
                  </div>
                </div>
                <div className="space-y-2">
                  <p className="text-base font-semibold text-[#000000] text-wrap break-words">
                    {" "}
                    {product?.order?.driver?.email}
                  </p>
                  <p className="text-base font-normal text-textColor">Email</p>
                </div>
                <div className="space-y-2">
                  <p className="text-base font-semibold text-[#000000]">
                    +{product?.order?.driver?.ccode}{" "}
                    {product?.order?.driver?.phone}
                  </p>
                  <p className="text-base font-normal text-textColor">
                    Phone Number
                  </p>
                </div>
              </div>
              <div className="px-4">
                <table class="table-auto w-full mt-5 ">
                  <thead className="bg-[#F6F6F6] ">
                    <tr className="text-textColor text-sm  font-medium  ">
                      <th className="text-sm font-medium py-3 text-start px-4">
                        Item
                      </th>
                      <th className="text-sm font-medium py-3 text-start ">
                        Quantity
                      </th>
                      <th className="text-sm font-medium py-3 text-end pr-4">
                        Price Per Qty
                      </th>
                    </tr>
                  </thead>
                  <tbody className="">
                    {/* only orders items start */}
                    {product?.products
                      ?.filter((item) => item.status == "accepted")
                      ?.map((val, index) => {
                        return (
                          <tr className="text-black text-sm text-center ">
                            <td className="text-sm font-semibold py-3 text-start px-4">
                              {val?.product_name}
                            </td>
                            <td className="text-sm font-semibold py-3 pl-6 text-start">
                              {val?.quantity}
                            </td>
                            <td className="text-sm font-semibold text-end pr-4">
                              IQD {val?.price}
                            </td>
                          </tr>
                        );
                      })}
                    {/* only orders items end */}
                    {/* footer part of table start */}
                    <tr className="text-black text-sm text-center border-t border-[#F1F5F9] ">
                      <td className="text-base font-medium text-[#808080] py-3 text-start px-4 ">
                        Subtotal
                      </td>
                      <td className="text-base font-medium text-[#808080] py-3 pl-6 text-start">
                        {quantity}
                      </td>
                      <td className="text-base font-medium text-[#808080] text-end pr-4">
                        IQD {total}
                      </td>
                    </tr>
                    <tr className="text-black text-sm text-center ">
                      <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
                        Transportation Charges
                      </td>
                      <td className="text-base font-medium text-[#808080] py-3 pl-6 text-start"></td>
                      <td className="text-base font-medium text-[#808080] text-end pr-4">
                        {product?.order?.shipping_type == "cargo"
                          ? `IQD ${product.order?.cargo}`
                          : `IQD ${product.order?.air_cargo}`}
                      </td>
                    </tr>
                    <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
                      <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
                        Service Charges
                      </td>
                      <td className="text-base font-medium text-[#808080] py-3 pl-6 text-start"></td>
                      <td className="text-base font-medium text-[#808080] text-end pr-4">
                        IQD {serivceCharge}
                      </td>
                    </tr>
                    <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
                      <td className="text-base font-medium text-[#808080] py-3 text-start px-4">
                        National Charges
                      </td>
                      <td className="text-base font-medium text-[#808080] py-3 pl-6 text-end"></td>
                      <td className="text-base font-medium text-[#808080] pr-4 text-end">
                        IQD {nationalCharge}
                      </td>
                    </tr>

                    <tr className="text-black text-sm text-center border-b border-[#F1F5F9]">
                      <td className="text-base font-bold  py-3 text-start px-4">
                        Total
                      </td>
                      <td className="text-base font-bold   py-3 pl-6 text-start"></td>
                      <td className="text-base font-bold text-end  pr-4">
                        IQD {product?.order?.total_amount}
                      </td>
                    </tr>
                    {/* footer part of table end  */}
                  </tbody>
                </table>
              </div>
              <div className="px-4 border-t border-[#F1F5F9] py-3">
                <Button
                  type={true}
                  onClick={() => closeModal()}
                  text="Cancel"
                  className="w-full rounded-lg py-3"
                ></Button>
              </div>
            </div>
          </DialogDescription>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default ViewDeliveryModal;
