import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import Button from "../components/common/Button/Button";
import DataTable from "../components/common/Tabel/DataTabel";
import TransitOrders from "./TransitOrders";
import useColumns from "../components/hooks/useColumns";
import ViewDeliveryModal from "../components/common/Modal/ViewDeliveryModal";
import RatingModal from "../components/common/Modal/RatingModal";
import { useNavigate } from "react-router-dom";
import DeliveryProofModal from "../components/common/Modal/DeliveryProofModal";
import { useDispatch, useSelector } from "react-redux";
import { getOrderDataByStatus } from "../redux/actions/assignment.action";
import { options } from "../data/data";
import GenericTable from "../components/custom/GenericTable";
import debounce from "lodash/debounce";
import { SearchDataChange } from "../components/hooks/SearchDataContext";

const BranchOrders = () => {
  const dispatch = useDispatch();
  const [activeButton, setActiveButton] = useState("Transit");
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const [status, setStatus] = useState({
    status: activeButton,
  });
  const { loading, total, orders } = useSelector((state) => state.assignment);
  const [openModal, setOpenModal] = useState({ open: false, data: null });
  const [openReviewModal, setOpenReviewModal] = useState({
    open: false,
    data: null,
  });
  const [openPoofModal, setOpenProofModal] = useState({
    open: false,
    data: null,
  });

  const handleDeliveryView = (data) => {
    setOpenModal({
      open: true,
      data: data,
    });
  };

  const handleView = (data) => {
    setOpenProofModal({ open: true, data: data });
  };

  const handleViewConfirm = (data) => {
    setOpenReviewModal({ open: true, data: data });
  };

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const { deliveryColumns } = useColumns({ handleDeliveryView });
  const { completedColumns } = useColumns({ handleView, handleViewConfirm });

  const renderContent = () => {
    switch (activeButton) {
      case "Transit":
        return <TransitOrders />;
      case "delivery":
        return (
          <GenericTable
            columns={deliveryColumns}
            data={orders}
            getData={getOrderDataByStatus}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={status}
            search={search}
            extra={status}
            message="Devlivery Orders Not Found"
          />
        );
      case "completed":
        return (
          <GenericTable
            columns={completedColumns}
            data={orders}
            getData={getOrderDataByStatus}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            total={total}
            loading={loading}
            status={status}
            search={search}
            extra={status}
            message="Completed Orders Not Found"
          />
        );

      default:
        return <TransitOrders />;
    }
  };

  return (
    <>
      <Layout title="Orders" role="branchAdmin">
        <div className="flex gap-5 px-4">
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${
              activeButton === "Transit" ? "bg-blue-500 text-white" : ""
            }`}
            text="Transit"
            type={activeButton === "Transit"}
            onClick={() => setActiveButton("Transit")}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${
              activeButton === "delivery" ? "bg-blue-500 text-white" : ""
            }`}
            text="Delivery"
            type={activeButton === "delivery"}
            onClick={() => {
              setActiveButton("delivery");
              setStatus({ status: "delivery" });
            }}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${
              activeButton === "completed" ? "bg-blue-500 text-white" : ""
            }`}
            text="Completed"
            type={activeButton === "completed"}
            onClick={() => {
              setActiveButton("completed");
              setStatus({ status: "completed" });
            }}
          />
        </div>
        {renderContent()}
        <ViewDeliveryModal
          openModal={openModal}
          closeModal={() => {
            setOpenModal({ open: false, data: null });
          }}
        />
        <RatingModal
          openModal={openReviewModal}
          closeModal={() => {
            setOpenReviewModal({ open: false, data: null });
          }}
        />
        <DeliveryProofModal
          openModal={openPoofModal}
          closeModal={() => {
            setOpenProofModal({ open: false, data: null });
          }}
        />
      </Layout>
    </>
  );
};

export default BranchOrders;
