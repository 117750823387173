import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import { imageUrl } from "../../../config/url";
import { useDispatch } from "react-redux";
import { deleteBranch, getBranch } from "../../../redux/actions/branch.action";
import { toFormData } from "axios";


const DeleteBranch = ({ data, setData }) => {
  const dispatch = useDispatch()
  const onClose = () => {
    setData({
      open: false,
      data: null
    })
  }
  const handleDelete = () => {
    dispatch(deleteBranch(toFormData({
      user_id: localStorage.getItem('user_id'),
      branch_id: data.data._id
    }))).unwrap().then(() => {
      onClose()
      dispatch(getBranch({
        user_id: localStorage.getItem('user_id')
      }))
    }).catch(() => {

    })
  }
  return (
    <>
      <Dialog open={data.open} onOpenChange={onClose}>
        <DialogContent className="px-5 pb-5">
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold"></h1>
              <div
                className="bg-[#F8FAFC] cursor-pointer w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={onClose}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="pt-3 pb-6 flex flex-col justify-center items-center  w-full">
              <img src={`${imageUrl}images/deleteBranch.png`} alt="delete branch" />
              <p className="text-xl font-semibold mt-2">Delete Branch</p>
              <p className="text-center text-lg mt-2 font-medium text-[#808080] w-[206px]">
                Are you sure you want to delete branch?
              </p>
              <div className="grid mt-7  grid-cols-2 gap-8 w-full px-12 pt-5  border-t border-[#F1F5F9]">
                <button onClick={onClose} className="bg-[#808080] text-white text-base font-bold py-3 rounded-lg w-full">
                  No
                </button>
                <Button
                  type={true}
                  text="Yes"
                  className="py-3 rounded-lg  text-base font-bold"
                  onClick={handleDelete}
                />
              </div>
            </div>
          </DialogDescription>

        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeleteBranch;
