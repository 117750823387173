import { createAsyncThunk } from "@reduxjs/toolkit";
import { endPoints } from "../../config";
import { toast } from "react-toastify";
import api from "../../config/api";


const getBranch = createAsyncThunk('GET_BRANCH', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH.LIST, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const addBranch = createAsyncThunk('ADD_BRANCH', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH.ADD, values);
        toast.success(data.ResponseMsg)
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const deleteBranch = createAsyncThunk('DELETE_BRANCH', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH.DELETE, values);
        toast.success(data.ResponseMsg)
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})



export { getBranch, addBranch ,deleteBranch }
