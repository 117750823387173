import React, { useCallback, useEffect, useState } from "react";
import Layout from "../components/common/Layouts/Layout";
import DataTable from "../components/common/Tabel/DataTabel";
import ReviewModal from "../components/common/Modal/ReviewModal";
import useColumns from "../components/hooks/useColumns";
import GenericTable from "../components/custom/GenericTable";
import { options } from "../data/data";
import { useSelector } from "react-redux";
import { getDashboard } from "../redux/actions/dashboard.action";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";

const Dashboard = () => {
  const [limitVal, setLimitVal] = useState(options[1]);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const { loading, total, dashboardData } = useSelector(
    (state) => state.dashboard
  );

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const [reviewModal, setReviewModal] = useState({
    open: false,
    data: null,
  });
  const handleReview = (row) => {
    setReviewModal({
      open: true,
      data: row,
    });
  };
  const { DashBoardcolumns } = useColumns({ handleReview });

  return (
    <>
      <Layout title="Dashboard">
        <GenericTable
          limitVal={limitVal}
          getData={getDashboard}
          setLimitVal={setLimitVal}
          total={total}
          loading={loading}
          search={search}
          data={dashboardData}
          columns={DashBoardcolumns}
          message="No Data Found"
        />
        <ReviewModal
          limitVal={limitVal}
          data={reviewModal}
          setData={setReviewModal}
        />
        <ReviewModal data={reviewModal} setData={setReviewModal} />
      </Layout>
    </>
  );
};

export default Dashboard;
