import { createSlice } from "@reduxjs/toolkit";
import { getCategory } from "../actions/category.action";

const categorySlice = createSlice({
    name: "category",
    initialState: {
        loading: false,
        categoryData: []
    },
    reducers: {

    },
    extraReducers: (builder) => {
        builder
            .addCase(getCategory.pending, (state, action) => {
                state.loading = true
            })
            .addCase(getCategory.fulfilled, (state, action) => {
                state.categoryData = action.payload.data
                state.loading = false
            })
            .addCase(getCategory.rejected, (state, action) => {
                state.loading = false
                state.categoryData = []
            })



    }
})

export default categorySlice.reducer;