
import { Navigate, Outlet, useLocation } from "react-router-dom";

const ResetGuard = () => {
    const forgot_id = window.localStorage.getItem('forgot_id');
    const location = useLocation()
    const verified=location.state?.verified;
    if (forgot_id && verified) {
        return <Outlet />
    } else {
      
        return <Navigate to={'/forget_password'} />
    }


}

export default ResetGuard;

