import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import { imageUrl } from "../../../config/url";

const DeliveryProofModal = ({ openModal, closeModal }) => {
  return (
    <>
      <Dialog open={openModal?.open} onOpenChange={closeModal}>
        <DialogContent>
          <DialogHeader>
            <div className="flex justify-between items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-lg font-bold">
                Delivery Proof
              </h1>
              <div
                className="bg-[#F8FAFC] w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="px-4 pb-5 space-y-5">
              <div className="space-y-5 mt-3">
                <p className="text-lg	font-semibold text-center">
                  {openModal?.data?.user?.name}
                </p>
                <div className="flex flex-col gap-y-3 justify-center items-center">
                  <img
                    src={openModal?.data?.delivery_img}
                    alt="proof"
                    className="w-full h-[50vh] object-cover"
                  />
                </div>
              </div>
              <div className="border border-[#E2E8F0] rounded-lg	 px-3 py-4">
                <p>{openModal?.data?.delivery_note}</p>
              </div>
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default DeliveryProofModal;
