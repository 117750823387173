import React from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
} from "./ModalComponent";
import { IoMdClose } from "react-icons/io";
import Button from "../Button/Button";
import { imageUrl } from "../../../config/url";
import { useDispatch } from "react-redux";
import { settleFundAmoutRequest } from "../../../redux/actions/amount.action";
import moment from "moment";

const RequestSettelment = ({ data, setData, startDate, endDate }) => {
  const dispatch = useDispatch();



  const closeModal = () => {
    setData({
      open: false,
      data: null,
    });
  };

  const handleSettleAmount = async () => {
    const payload = {
      branch: data?.data?.branch?._id,
      amount: data?.data?.totalAmount,
      start_date: startDate
        ? moment(startDate).format("YYYY-MM-DD")
        : moment().subtract(1, "month").format("YYYY-MM-DD"),
      end_date: endDate
        ? moment(endDate).format("YYYY-MM-DD")
        : moment().format("YYYY-MM-DD"),
    };
    dispatch(settleFundAmoutRequest(payload));
    closeModal();
  };

  return (
    <>
      <Dialog open={data.open} onOpenChange={closeModal}>
        <DialogContent className="px-5 pt-5 pb-5">
          <DialogHeader>
            <div className="flex justify-center relative items-center w-full border-b border-[#F1F5F9] px-4 py-4">
              <h1 className="text-[#0F172A] text-2xl text-center font-bold">
                Settle Amount
              </h1>
              <div
                className="bg-[#F8FAFC] absolute right-3 w-10 h-10 rounded-lg flex justify-center items-center"
                onClick={closeModal}
              >
                <IoMdClose className="text-[#64748B]" />
              </div>
            </div>
          </DialogHeader>
          <DialogDescription>
            <div className="py-3 flex flex-col justify-center items-center space-y-2 w-full">
              {/* <img src={`${imageUrl}images/deleteBranch.png`} alt="delete branch" /> */}

              <p className="text-center mb-4 text-lg font-medium text-[#808080] w-[206px]">
                Are you want to settle IQD {data?.data?.totalAmount} amount?
              </p>
              <div className="grid grid-cols-2 gap-8 w-full px-12 pt-5 border-t border-[#F1F5F9]">
                <button
                  onClick={closeModal}
                  className="bg-[#808080] text-white text-base font-bold py-3 rounded-lg w-full"
                >
                  Cancel
                </button>
                <Button
                  type={true}
                  onClick={() => {
                    handleSettleAmount();
                  }}
                  text="Yes"
                  className="py-3 rounded-lg  text-base font-bold"
                />
              </div>
            </div>
          </DialogDescription>
          {/* <DialogFooter>footeerrr</DialogFooter> */}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default RequestSettelment;
