import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api";
import { toast } from "react-toastify";
import { endPoints } from "../../config";

const submitOrder = createAsyncThunk('SUBMIT_ORDER', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.PRODUCT.SUBMIT_ORDER, values);
        toast.success(data.ResponseMsg)
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const rejectOrApprovedProduct = createAsyncThunk('REJECT_OR_APPROVED_PRODUCT', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.PRODUCT.REJECTAPPROVED, values);
        toast.success(data.ResponseMsg)
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

export { rejectOrApprovedProduct, submitOrder }