import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../config/api";
import { endPoints } from "../../config";
import { toast } from "react-toastify";

const getUserData = createAsyncThunk("GET_USER", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.USERLIST, values);
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg);
        return rejectWithValue(error);
    }
})

const assignOrdertoDriver = createAsyncThunk("ASSIGN_ORDER_TO_DRIVER", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ASSIGN_ORDER_TO_DRIVER, values);
        toast.success(data?.ResponseMsg);
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg);
        return rejectWithValue(error);
    }
})

export { getUserData, assignOrdertoDriver };