import React from "react";
import { imageUrl } from "../../config/url";

const BreadCrumb = ({ onClick,title="Back" }) => {
  return (
    <div
      className="bg-white cursor-pointer py-6 px-6 rounded-xl"
      onClick={onClick}
      style={{ pointerEvents: "auto" }}
    >
      <div className="flex gap-3 item-center justify-start">
        <img src={`${imageUrl}icons/arrowIcon.svg`} alt="arrow icon" />
        <p className="text-textColor text-base font-medium">{title}</p>
      </div>
    </div>
  );
};

export default BreadCrumb;
