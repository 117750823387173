import { createAsyncThunk } from "@reduxjs/toolkit";
import { toast } from "react-toastify";
import api from "../../config/api";
import { endPoints } from "../../config";

const getDriverData = createAsyncThunk('GET_DRIVER', async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.LIST, values);
        return data
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const addDriverData = createAsyncThunk("ADD_DRIVER", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.ADD_DRIVER, values);
        toast.success(data.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error.ResponseMsg)
        return rejectWithValue(error)
    }
})

const deleteDriverData = createAsyncThunk("DELETE_DRIVER", async (values, { rejectWithValue }) => {
    try {
        const data = await api.post(endPoints.BRANCH_ADMIN.DELETE_DRIVER, values);
        toast.success(data.ResponseMsg)
        return data;
    } catch (error) {
        toast.error(error?.ResponseMsg);
        return rejectWithValue(error);
    }
})

export { getDriverData, addDriverData, deleteDriverData } 