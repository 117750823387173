import React, { useCallback, useEffect, useState } from "react";
import Button from "../components/common/Button/Button";
import Layout from "../components/common/Layouts/Layout";
import useColumns from "../components/hooks/useColumns";
import ViewOrdersModal from "../components/common/Modal/ViewOrdersModal";
import GenericTable from "../components/custom/GenericTable";
import { options } from "../data/data";
import { useSelector } from "react-redux";
import { getOrders } from "../redux/actions/order.action";
import { getAssignment } from "../redux/actions/assignment.action";
import { SearchDataChange } from "../components/hooks/SearchDataContext";
import debounce from "lodash/debounce";

const Orders = () => {
  const [activeButton, setActiveButton] = useState("approved");
  const [activeTab, setActiveTab] = useState("cargo");
  const { loading, total, orderData } = useSelector((state) => state.order);
  const [limitVal, setLimitVal] = useState(options[1]);
  const [limitTransitVal, setLimitTransitVal] = useState(options[1]);
  const {
    total: assignmentTotal,
    loading: assignmentLoading,
    assignmentData,
  } = useSelector((state) => state.assignment);
  const [search, setSearch] = useState("");
  const { searchQuery } = SearchDataChange();
  const [viewOrderModal, setViewOrderModal] = useState({
    open: false,
    data: null,
  });

  const debouncedSearch = useCallback(
    debounce((query) => {
      setSearch(query);
    }, 500),
    []
  );

  useEffect(() => {
    debouncedSearch(searchQuery);
  }, [searchQuery]);

  const handleView = (row) => {
    setViewOrderModal({
      open: true,
      data: row,
    });
  };

  const { Orders, Transitcolumns } = useColumns({
    handleView,
  });

  return (
    <>
      <Layout title="Orders">
        <div className="flex max-sm:flex-wrap gap-5 px-4">
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[120px] ${activeButton === "Approved" ? "bg-blue-500 text-white" : ""
              }`}
            text="Approved"
            type={activeButton === "approved"}
            onClick={() => setActiveButton("approved")}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[170px] ${activeButton === "Order Confirmed" ? "bg-blue-500 text-white" : ""
              }`}
            text="Order Confirm"
            type={activeButton === "confirm"}
            onClick={() => setActiveButton("confirm")}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[130px] ${activeButton === "Warehouse" ? "bg-blue-500 text-white" : ""
              }`}
            text="Warehouse"
            type={activeButton === "warehouse"}
            onClick={() => setActiveButton("warehouse")}
          />
          <Button
            className={`py-2 rounded-[10px] font-medium text-lg w-[90px] ${activeButton === "Transit" ? "bg-blue-500 text-white" : ""
              }`}
            text="Transit"
            type={activeButton === "transit"}
            onClick={() => setActiveButton("transit")}
          />
        </div>

        {activeButton != "transit" && (
          <GenericTable
            search={search}
            extra={{
              status: activeButton,
            }}
            total={total}
            limitVal={limitVal}
            setLimitVal={setLimitVal}
            getData={getOrders}
            loading={loading}
            params={activeButton}
            columns={Orders}
            data={orderData}
            height="h-[73vh]"
            message=" Orders Not Found"
          />
        )}

        {activeButton == "transit" && (
          <>
            <div>
              <div className="w-full flex mt-6">
                <div
                  className={`w-full cursor-pointer  py-3 ${activeTab === "cargo"
                      ? "font-semibold text-base border-b-2 border-[#383F90]"
                      : "text-base font-normal border-b-2 border-[#E7E6E9] text-[#808080]"
                    }`}
                  onClick={() => {
                    setActiveTab("cargo");
                  }}
                >
                  Cargo
                </div>
                <div
                  className={`w-full cursor-pointer  py-3 ${activeTab === "air_cargo"
                      ? "font-semibold text-base border-b-2 border-[#383F90]"
                      : "text-base font-normal border-b-2 border-[#E7E6E9] text-[#808080]"
                    }`}
                  onClick={() => {
                    setActiveTab("air_cargo");
                  }}
                >
                  Air Cargo
                </div>
              </div>
            </div>

            <div>
              <GenericTable
                data={assignmentData}
                extra={{
                  shipping_type: activeTab,
                }}
                total={assignmentTotal}
                loading={assignmentLoading}
                params={activeTab}
                getData={getAssignment}
                limitVal={limitTransitVal}
                setLimitVal={setLimitTransitVal}
                search={search}
                columns={Transitcolumns}
                height="h-[68vh]"
                message="Orders Not Found"
              />
            </div>
          </>
        )}

        <ViewOrdersModal
          limit={limitVal}
          data={viewOrderModal}
          setData={setViewOrderModal}
        />
        {/* <ViewOrdersModal
          openModal={openConfirm}
          closeModal={() => {
            setOpenConfirm(false);
          }}
          type="confirm"
          title="Confirmed Orders"
        /> */}
        {/* <ViewOrdersModal
          openModal={openWareHouse}
          closeModal={() => {
            setOpenWareHouse(false);
          }}
          type="warehouse"
          title="View"
        /> */}
      </Layout>
    </>
  );
};

export default Orders;
