import React, { useEffect } from "react";
import { IoMdSearch } from "react-icons/io";
import * as Popover from "@radix-ui/react-popover";
import { FiSearch } from "react-icons/fi";
import { SearchDataChange } from "../../hooks/SearchDataContext";
import { useLocation } from "react-router-dom";

const SearchInput = ({ placeholder, type }) => {
  const { searchQuery, setSearchQuery } = SearchDataChange();
  useEffect(() => {
    setSearchQuery("")
  }, [useLocation])

  return (
    <div className="relative">
      <div className="absolute  inset-y-0 start-0 flex items-center ps-3 pointer-events-none">
        <IoMdSearch className="text-[#64748B]" />
      </div>
      <input
        type={type}
        id="search"
        className="block w-full px-4 py-2.5 ps-10 text-sm text-gray-900 rounded-[15px] bg-inputBG placeholder:text-[#64748B]"
        placeholder={placeholder}
        value={searchQuery}
        onChange={(e) => {
          setSearchQuery(e.target.value);
        }}
      />
    </div>
  );
};
const HeaderSearchBar = ({ placeholder, type }) => {
  return (
    <div className="items-center justify-center w-full sm:border rounded-[15px]  border-borderColor">
      <div className="max-sm:hidden">
        <SearchInput placeholder={placeholder} type={type} />
      </div>
      <div className="sm:hidden">
        <Popover.Root>
          <Popover.Trigger>
            <button>
              <FiSearch className="text-lg" />
            </button>
          </Popover.Trigger>
          <Popover.Content className="py-4" width="360px">
            <SearchInput placeholder={placeholder} type={type} />
          </Popover.Content>
        </Popover.Root>
      </div>
    </div>
  );
};

export default HeaderSearchBar;
